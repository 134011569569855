import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";

export function render(references) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
        maxHeight: 450 + "px",
        overflowY: "auto",
    }], (elems_1 = toList(delay(() => {
        let elems, children_2, children, children_6;
        return (references.length === 0) ? singleton(createElement("div", {
            className: "has-text-centered",
            children: "No references were found for this Booking",
        })) : singleton(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems = [(children_2 = singleton_1((children = ofArray([createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Reference Type",
        }), createElement("th", {
            className: "p-2",
            scope: "col",
            children: "Reference Number",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_6 = toList(delay(() => map((reference) => {
            const children_4 = ofArray([createElement("td", {
                className: "p-2",
                children: reference.Name,
            }), createElement("td", {
                className: "p-2",
                children: reference.Number,
            })]);
            return createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            });
        }, references))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

