import { Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";

export class SelectableTabs extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Details", "SpecialInstructions", "Items", "Documents", "References"];
    }
}

export function SelectableTabs_$reflection() {
    return union_type("CView.UI.BookingDomain.SelectableTabs", [], SelectableTabs, () => [[], [], [], [], []]);
}

