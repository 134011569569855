import { billingCustomsSpendChart, billingWarehouseSpendChart, billingSpendDonutChartDonutChart, billingAllModesDonutChart, billingSpendOverTimeChart, billingPastDueBalanceChart, billingCurrentBalanceChart, shipmentEmissionIntensityByProductChart, shipmentCO2EmissionByMonthChart, shipmentTransitTimeChart, shipmentOnTimePercentageChart } from "./Chart.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/List.js";

export const Interop_shipmentOnTimePercentageChart = shipmentOnTimePercentageChart;

export const Interop_shipmentTransitTimeChart = shipmentTransitTimeChart;

export const Interop_shipmentCO2EmissionByMonthChart = shipmentCO2EmissionByMonthChart;

export const Interop_shipmentEmissionIntensityByProductChart = shipmentEmissionIntensityByProductChart;

export const Interop_billingCurrentBalanceChart = billingCurrentBalanceChart;

export const Interop_billingPastDueBalanceChart = billingPastDueBalanceChart;

export const Interop_billingSpendOverTimeChart = billingSpendOverTimeChart;

export const Interop_billingAllModesDonutChart = billingAllModesDonutChart;

export const Interop_billingSpendDonutChartDonutChart = billingSpendDonutChartDonutChart;

export const Interop_billingWarehouseSpendChart = billingWarehouseSpendChart;

export const Interop_billingCustomsSpendChart = billingCustomsSpendChart;

export function Chart_shipmentOnTimePercentageChart(canvasId, models, handler) {
    return singleton((dispatch) => {
        Interop_shipmentOnTimePercentageChart(canvasId, models, (msg) => {
            dispatch(handler(msg));
        });
    });
}

export function Chart_shipmentTransitTimeChart(canvasId, models, handler) {
    return singleton((dispatch) => {
        Interop_shipmentTransitTimeChart(canvasId, models, (msg) => {
            dispatch(handler(msg));
        });
    });
}

export function Chart_shipmentCO2EmissionByMonthChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_shipmentCO2EmissionByMonthChart(canvasId, models);
    });
}

export function Chart_shipmentEmissionIntensityByProductChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_shipmentEmissionIntensityByProductChart(canvasId, models);
    });
}

export function Chart_billingCurrentBalanceChart(canvasId, models, handler) {
    return singleton((dispatch) => {
        Interop_billingCurrentBalanceChart(canvasId, models, (msg) => {
            dispatch(handler(msg));
        });
    });
}

export function Chart_billingPastDueBalanceChart(canvasId, models, handler) {
    return singleton((dispatch) => {
        Interop_billingPastDueBalanceChart(canvasId, models, (msg) => {
            dispatch(handler(msg));
        });
    });
}

export function Chart_billingSpendOverTimeChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_billingSpendOverTimeChart(canvasId, models);
    });
}

export function Chart_billingAllModesDonutChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_billingAllModesDonutChart(canvasId, models);
    });
}

export function Chart_billingSpendDonutChartDonutChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_billingSpendDonutChartDonutChart(canvasId, models);
    });
}

export function Chart_billingWarehouseSpendChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_billingWarehouseSpendChart(canvasId, models);
    });
}

export function Chart_billingCustomsSpendChart(canvasId, models) {
    return singleton((dispatch) => {
        Interop_billingCustomsSpendChart(canvasId, models);
    });
}

