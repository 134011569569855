﻿import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ClickAnalyticsPlugin} from '@microsoft/applicationinsights-clickanalytics-js';

let appInsights = null;

/**
 * @param {String} authenticatedUserId
 */
export function init(authenticatedUserId) {    
    try {
        if (!appInsights) {
            const clickPluginInstance = new ClickAnalyticsPlugin();
            const clickPluginConfig = {
                autoCapture: true,
                dataTags: {
                    useDefaultContentNameOrId: true
                }
            };
            const configObj = {
                connectionString: process.env.APPLICATION_INSIGHTS_CONNECTION_STRING,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true,
                extensions: [clickPluginInstance],
                extensionConfig: {
                    [clickPluginInstance.identifier]: clickPluginConfig
                },
            };

            appInsights = new ApplicationInsights({ config: configObj });
            appInsights.loadAppInsights();
            appInsights.trackPageView();

            let validatedId = authenticatedUserId.replace(/[,;=| ]+/g, "_");
            appInsights.setAuthenticatedUserContext(validatedId);
        }
    } catch (error) {
        console.error("An error occurred while initializing application insights: ", error);
    }
}