import { Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { class_type, record_type, array_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Account_$reflection, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { render as render_1, Msg as Msg_1, update as update_1, init as init_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/ShipmentDashboard.fs.js";
import { render as render_2, Msg as Msg_2, update as update_2, init as init_2, Msg_$reflection as Msg_$reflection_2, State_$reflection as State_$reflection_2 } from "../Components/BillingDashboard.fs.js";
import { BillingIntentQuery_$reflection, ShipmentPerformanceQuery_$reflection, ShipmentInTransitQuery_$reflection } from "../Models.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { createElement } from "react";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { equals, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class SelectableTabs extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Billing", "Shipment", "None"];
    }
}

export function SelectableTabs_$reflection() {
    return union_type("CView.UI.Pages.Dashboard.SelectableTabs", [], SelectableTabs, () => [[], [], []]);
}

export class State extends Record {
    constructor(User, ActiveTab, ShipmentDashboard, BillingDashboard, Accounts) {
        super();
        this.User = User;
        this.ActiveTab = ActiveTab;
        this.ShipmentDashboard = ShipmentDashboard;
        this.BillingDashboard = BillingDashboard;
        this.Accounts = Accounts;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Dashboard.State", [], State, () => [["User", User_$reflection()], ["ActiveTab", SelectableTabs_$reflection()], ["ShipmentDashboard", State_$reflection_1()], ["BillingDashboard", State_$reflection_2()], ["Accounts", array_type(Account_$reflection())]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "InTransitShipmentSearch", "DeliveredShipmentSearch", "ShipmentUnpinned", "BillingSearch"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Dashboard.Intent", [], Intent, () => [[], [["Item", ShipmentInTransitQuery_$reflection()]], [["Item", ShipmentPerformanceQuery_$reflection()]], [["Item", class_type("System.Guid")]], [["Item", BillingIntentQuery_$reflection()]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetActiveTab", "ShipmentDashboardMsg", "BillingDashboardMsg", "ResetShipmentDashboardState", "ResetBillingDashboardState"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Dashboard.Msg", [], Msg, () => [[["Item", SelectableTabs_$reflection()]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [], []]);
}

export function init(user, accounts) {
    const patternInput = init_1(user, accounts);
    const patternInput_1 = init_2(user, accounts);
    const activeTabCommand = user.CanViewShipments ? singleton((dispatch) => {
        dispatch(new Msg(0, [new SelectableTabs(1, [])]));
    }) : singleton((dispatch_1) => {
        dispatch_1(new Msg(0, [new SelectableTabs(0, [])]));
    });
    return [new State(user, new SelectableTabs(2, []), patternInput[0], patternInput_1[0], accounts), Cmd_batch(toList(delay(() => append(user.CanViewShipments ? singleton_1(Cmd_map((Item) => (new Msg(1, [Item])), patternInput[1])) : empty(), delay(() => append(user.CanViewBilling ? singleton_1(Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1])) : empty(), delay(() => singleton_1(activeTabCommand))))))))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 3: {
            const patternInput_2 = init_1(state.User, state.Accounts);
            return [new State(state.User, state.ActiveTab, patternInput_2[0], state.BillingDashboard, state.Accounts), Cmd_map((Item_2) => (new Msg(1, [Item_2])), patternInput_2[1]), new Intent(0, [])];
        }
        case 4: {
            const patternInput_3 = init_2(state.User, state.Accounts);
            return [new State(state.User, state.ActiveTab, state.ShipmentDashboard, patternInput_3[0], state.Accounts), Cmd_map((Item_3) => (new Msg(2, [Item_3])), patternInput_3[1]), new Intent(0, [])];
        }
        case 1: {
            const patternInput_4 = update_1(msg.fields[0], state.ShipmentDashboard);
            const shipmentDashboardMsgIntent = patternInput_4[2];
            const intent = (shipmentDashboardMsgIntent.tag === 1) ? (new Intent(1, [shipmentDashboardMsgIntent.fields[0]])) : ((shipmentDashboardMsgIntent.tag === 3) ? (new Intent(2, [shipmentDashboardMsgIntent.fields[0]])) : ((shipmentDashboardMsgIntent.tag === 2) ? (new Intent(3, [shipmentDashboardMsgIntent.fields[0]])) : (new Intent(0, []))));
            return [new State(state.User, state.ActiveTab, patternInput_4[0], state.BillingDashboard, state.Accounts), Cmd_map((Item_4) => (new Msg(1, [Item_4])), patternInput_4[1]), intent];
        }
        case 2: {
            const patternInput_5 = update_2(msg.fields[0], state.BillingDashboard);
            const billingDashboardMsgIntent_1 = patternInput_5[2];
            const intent_1 = (billingDashboardMsgIntent_1.tag === 1) ? (new Intent(4, [billingDashboardMsgIntent_1.fields[0]])) : (new Intent(0, []));
            return [new State(state.User, state.ActiveTab, state.ShipmentDashboard, patternInput_5[0], state.Accounts), Cmd_map((Item_5) => (new Msg(2, [Item_5])), patternInput_5[1]), intent_1];
        }
        default: {
            const activeTab = msg.fields[0];
            switch (activeTab.tag) {
                case 1: {
                    const patternInput = update_1(new Msg_1(17, []), state.ShipmentDashboard);
                    return [new State(state.User, activeTab, patternInput[0], state.BillingDashboard, state.Accounts), Cmd_map((Item) => (new Msg(1, [Item])), patternInput[1]), new Intent(0, [])];
                }
                case 0: {
                    const patternInput_1 = update_2(new Msg_2(7, []), state.BillingDashboard);
                    return [new State(state.User, activeTab, state.ShipmentDashboard, patternInput_1[0], state.Accounts), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1]), new Intent(0, [])];
                }
                default:
                    return [new State(state.User, activeTab, state.ShipmentDashboard, state.BillingDashboard, state.Accounts), Cmd_none(), new Intent(0, [])];
            }
        }
    }
}

export function render(state, dispatch) {
    let elems_4, elems_2, children_2, elems_3;
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_4 = [createElement("div", createObj(ofArray([["id", "main_tabs"], ["className", join(" ", ["tabs", "is-small", "fixed-tabs"])], (elems_2 = [(children_2 = toList(delay(() => {
            let elems;
            return append(state.User.CanViewShipments ? singleton_1(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => ((state.ActiveTab.tag === 1) ? singleton_1("is-active") : singleton_1("")))))))))], (elems = [createElement("a", {
                onClick: (_arg) => {
                    dispatch(new Msg(3, []));
                    dispatch(new Msg(0, [new SelectableTabs(1, [])]));
                },
                children: "Shipments",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => {
                let elems_1, fmt;
                return state.User.CanViewBilling ? singleton_1(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton_1("is-active") : empty()))))))))], (elems_1 = [createElement("a", createObj(ofArray([["onClick", (_arg_1) => {
                    dispatch(new Msg(4, []));
                    dispatch(new Msg(0, [new SelectableTabs(0, [])]));
                }], (fmt = printf("Billing"), fmt.cont((value_14) => ["children", value_14]))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
            }));
        })), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "tabcontainer"], ["id", "dashboards"], (elems_3 = toList(delay(() => {
            const matchValue_1 = state.ActiveTab;
            return (matchValue_1.tag === 1) ? singleton_1(render_1(state.ShipmentDashboard, (arg) => {
                dispatch(new Msg(1, [arg]));
            })) : ((matchValue_1.tag === 0) ? singleton_1(render_2(state.BillingDashboard, (arg_1) => {
                dispatch(new Msg(2, [arg_1]));
            })) : singleton_1(defaultOf()));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
    }
}

