import Chart from 'chart.js/auto';


/**
 * @typedef {Object} ShipmentDeliveryPerformance
 * @property {string} ModeOfTransport 
 * @property {string} PortOfLadingCityCode  
 * @property {string} PortOfLadingCityCountryCode 
 * @property {string} PortOfDischargeCityCode  
 * @property {string} PortOfDischargeCityCountryCode  
 * @property {string} PortOfLadingLocation 
 * @property {string} PortOfDischargeLocation  
 * @property {number} ShipmentCount  
 * @property {number} TotalWeight  
 * @property {number} MedianTransitTime  
 * @property {number} OnTimePercentage  
 * @property {Date} PastAggregateDate 
 * @property {Date} CurentAggregateDate
 */

/**
 * @typedef {Object} EmissionMetricsByDate
 * @property {number} EmissionsTotal  
 * @property {number} EmissionsIntensity 
 * @property {number} EmissionsWellToTank  
 * @property {number} EmissionsTankToWheel  
 * @property {Date} AggregateDate 
 * @property {string} FormattedDate  
 */

/**
 * @typedef {Object} EmissionProductIntensityByDate
 * @property {string} Product  
 * @property {number} EmissionsIntensity  
 * @property {Date} AggregateDate 
 * @property {string} FormattedDate 
 */

/**
 * @typedef {Object} PeriodicInvoiceBalance
 * @property {number} AmountUsd  .
 * @property {string} DateRange 
 * @property {Date} StartDate  
 * @property {Date|null} EndDate 
 */

/**
 * @typedef {Object} InvoiceTransportProductDetails
 * @property {string} Period 
 * @property {string} Product  
 * @property {string} ServiceGroup  
 * @property {number} TotalChargeableWeight 
 * @property {number} AmountUsd  
 * @property {number} ShipmentCount  
 * @property {boolean} IsTransportation  
 */

/**
 * @typedef {Object} InvoiceNonTransportProductAmount
 * @property {string} Period  
 * @property {string} Product  
 * @property {number} AmountUsd 
 */




const airHex = '#48c78e';
const oceanHex = '#3e8ed0';
const groundHex = '#dde5ec';
const warehouseHex = '#0A0A0A'
const brokerageHex = '#2D7D8F'
const urgentAirHex = '#FF3860'

const countHex = '#01803E';
const volumeHex = '#132D76';
const transitMedianHex = '#7CBF9A';
const transitCountHex = '#7DA4BC';

const transparentize = hex => {
    const opacity = 0.8;
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
}


/**
 * @callback BarClicked
 * @param {ShipmentDeliveryPerformance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {ShipmentDeliveryPerformance []} models
 * @param {BarClicked} handler
*/


export function shipmentOnTimePercentageChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => `${data.PortOfLadingCityCode} - ${data.PortOfDischargeCityCode}`)

        const lineData = models.map(data => parseFloat(data.OnTimePercentage))

        const barData = models.map(data => data.ShipmentCount)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: '% On-Time',
                        backgroundColor: countHex,
                        borderColor: countHex,
                        data: lineData,
                        order: 1,
                        yAxisID: 'right-y-axis'
                    },
                    {
                        type: 'bar',
                        label: 'Shipment Count',
                        backgroundColor: volumeHex,
                        borderColor: volumeHex,
                        data: barData,
                        order: 2,
                        yAxisID: 'left-y-axis'
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        }
                    }
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];

                        if (dataset.type === 'bar') {
                      
                            handler(models[dataIndex]);
                        }
                    }
                },
            }
        });

    }, 0);
}

/**
 * @callback BarClicked
 * @param {ShipmentDeliveryPerformance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {ShipmentDeliveryPerformance []} models
 * @param {BarClicked} handler
*/
export function shipmentTransitTimeChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => `${data.PortOfLadingCityCode} - ${data.PortOfDischargeCityCode}`)

        const lineData = models.map(data => parseFloat(data.MedianTransitTime))

        const barData = models.map(data => data.ShipmentCount)

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Median Transit Time',
                        backgroundColor: transitMedianHex,
                        borderColor: transitMedianHex,
                        data: lineData,
                        order: 1,
                        yAxisID: 'right-y-axis'
                    },
                    {
                        type: 'bar',
                        label: 'Shipment Count',
                        backgroundColor: transitCountHex,
                        borderColor: transitCountHex,
                        data: barData,
                        order: 2,
                        yAxisID: 'left-y-axis'
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,  
                        }
                    }
                },
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];

                        if (dataset.type === 'bar') {

                            handler(models[dataIndex]);
                        }
                    }
                },
            }
        });

    }, 0);
}

/**
 * 
 * @param {String} canvasId
 * @param {EmissionMetricsByDate []} models
*/
export function shipmentCO2EmissionByMonthChart(canvasId, models) {

    setTimeout(() => {

        const labels = models.map(data => data.FormattedDate)

        const lineData = models.map(data => parseFloat(data.EmissionsIntensity))

        const barData = models.map(data => parseFloat(data.EmissionsTotal))

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'bar',
                        label: 'WTW CO2e (tonnes)',
                        backgroundColor: '#132D76',
                        data: barData,
                        order: 2,
                        yAxisID: 'left-y-axis'
                    },
                    {
                        type: 'line',
                        label: 'CO2e Intensity (g/tonne km)',
                        backgroundColor: '#F39200',
                        borderColor: '#F39200',
                        data: lineData,
                        order: 1,
                        yAxisID: 'right-y-axis'
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        }
                    }
                }
            }
        });
    }, 0);
}

/**
 * 
 * @param {String} canvasId
 * @param {EmissionProductIntensityByDate []} models
*/
export function shipmentEmissionIntensityByProductChart(canvasId, models) {

    setTimeout(() => {

        const colors = ['#7DA4BC', '#132D76', '#F39200', '#2D7D8F'];

        const labels = [...new Set(models.map(data => data.FormattedDate))];

        const groupedData = Object.groupBy(models, item => item.Product)

        const datasets = [];
        const entries = Object.entries(groupedData);

        entries.forEach(([product, data], index) => {
            let counts = data.map(item => parseFloat(item.EmissionsIntensity)); 

            let color = colors[index % colors.length];

            datasets.push({
                label: product,                
                data: counts,               
                backgroundColor: color,    
                borderColor: color,        
                tension: 0.4
            });

        })

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'line',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true  
                    }
                }
            }
        });
    }, 0);
}

/**
 * @callback BarClicked
 * @param {PeriodicInvoiceBalance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {PeriodicInvoiceBalance []} models
 * @param {BarClicked} handler
*/
export function billingCurrentBalanceChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => data.DateRange)

        const barData = models.map(data => parseFloat(data.AmountUsd))

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        axis: 'y',
                        label: 'Amount USD',
                        backgroundColor: '#132D76',
                        data: barData,
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];


                        handler(models[dataIndex]);
                    }
                }
            }
        });

    }, 0);
}

/**
 * @callback BarClicked
 * @param {PeriodicInvoiceBalance} value
 * @returns {void}
 * 
 * @param {String} canvasId
 * @param {PeriodicInvoiceBalance []} models
 * @param {BarClicked} handler
*/
export function billingPastDueBalanceChart(canvasId, models, handler) {

    setTimeout(() => {

        const labels = models.map(data => data.DateRange)

        const barData = models.map(data => parseFloat(data.AmountUsd))

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        chart = new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        axis: 'y',
                        label: 'Amount USD',
                        backgroundColor: '#C44D36',
                        data: barData,
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                onClick: (event, elements) => {
                    if (elements.length > 0) {

                        const element = elements[0];
                        const datasetIndex = element.datasetIndex;
                        const dataIndex = element.index;
                        const dataset = chart.data.datasets[datasetIndex];

                        handler(models[dataIndex]);
                    }
                }
            }
        });

    }, 0);
}

/**
 * @param {String} canvasId
 * @param {InvoiceTransportProductDetails []} models
*/
export function billingSpendOverTimeChart(canvasId, models) {

    setTimeout(() => {

        const labels = [...new Set(models.map(data => data.Period))];

        const groupedByPeriod = Object.groupBy(models, item => item.Period);

        const summaryByPeriod = Object.entries(groupedByPeriod).map(([period, group]) => {
            const totalWeight = group.reduce((sum, item) => sum + parseFloat(item.TotalChargeableWeight), 0);
            const totalAmount = group.reduce((sum, item) => sum + parseFloat(item.AmountUsd), 0);
            const totalShipmentCount = group.reduce((sum, item) => sum + item.ShipmentCount, 0);

            return {
                Period: period,
                TotalWeight: totalWeight,
                TotalAmount: totalAmount,
                TotalShipmentCount: totalShipmentCount
            };
        });

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        type: 'bar',
                        label: 'Tonnage',
                        backgroundColor: '#132D76',
                        data: summaryByPeriod.map(data => data.TotalWeight),
                        order: 2,
                        yAxisID: 'left-y-axis'
                    },
                    {
                        type: 'bar',
                        label: 'Spend USD',
                        backgroundColor: '#01803E',
                        data: summaryByPeriod.map(data => data.TotalAmount),
                        order: 2,
                        yAxisID: 'left-y-axis'
                    },
                    {
                        type: 'line',
                        label: 'Shipment Count',
                        backgroundColor: '#7DA4BC',
                        borderColor: '#7DA4BC',
                        data: summaryByPeriod.map(data => data.TotalShipmentCount),
                        order: 1,
                        yAxisID: 'right-y-axis'
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left'
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        }
                    }
                }
            }
        });

    }, 0);
}

/**
 * @param {String} canvasId
 * @param {InvoiceTransportProductDetails []} models
*/
export function billingAllModesDonutChart(canvasId, models) {

    setTimeout(() => {

        const labels = [...new Set(models.map(data => data.Product))];

        const calculateTotalAmount = (details, product) =>
            details
                .filter(item => item.Product === product)
                .reduce((sum, item) => sum + parseFloat(item.AmountUsd), 0);

        const totalAmounts = labels.reduce((acc, label) => {
            acc[label] = calculateTotalAmount(models, label);
            return acc;
        }, {});

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Amount USD',
                        backgroundColor: ['#01803E', '#575757', '#132D76', '#7DA4BC'],
                        data: [totalAmounts.Air, totalAmounts.Ground, totalAmounts.Ocean, totalAmounts.Rail],
                        hoverOffset: 4
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'left',
                        align: 'start'
                    }
                }
            }
        });

    }, 0);
}

/**
 * @param {String} canvasId
 * @param {InvoiceTransportProductDetails []} models
*/
export function billingSpendDonutChartDonutChart(canvasId, models) {

    setTimeout(() => {

        const labels = [...new Set(models.map(data => data.ServiceGroup))];

        const groupedByService = Object.groupBy(models, item => item.ServiceGroup);

        const totalAmountsArray = Object.entries(groupedByService).map(([service, items]) => (items.reduce((sum, item) => sum + parseFloat(item.AmountUsd), 0)));

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Amount USD',
                        backgroundColor: ['#01803E', '#132D76', '#575757', '#7DA4BC', '#7CBF9A', '#014D27', '#0E214E', '#3B3B3B', '#5E7C91','#5D9876'],
                        data: totalAmountsArray,
                        hoverOffset: 4
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'left',
                        align: 'start'
                    }
                }
            }
        });

    }, 0);
}



/**
 * @param {String} canvasId
 * @param {InvoiceNonTransportProductAmount []} models
*/
export function billingWarehouseSpendChart(canvasId, models) {

    setTimeout(() => {

        const labels = [...new Set(models.map(data => data.Period))];

        const warehouseData = models.filter(data => data.Product === "Warehouse").map(data => parseFloat(data.AmountUsd));

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        axis: 'y',
                        label: 'Amount USD',
                        backgroundColor: '#01803E',
                        data: warehouseData,
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
            }
        });

    }, 0);
}


/**
 * @param {String} canvasId
 * @param {InvoiceNonTransportProductAmount []} models
*/
export function billingCustomsSpendChart(canvasId, models) {

    setTimeout(() => {

        const labels = [...new Set(models.map(data => data.Period))];

        const customsData = models.filter(data => data.Product === "Customs Brokerage").map(data => parseFloat(data.AmountUsd));

        let chart = Chart.getChart(canvasId);

        if (chart) {
            console.info(canvasId, ' destroying...');
            chart.destroy();
        }

        new Chart(document.getElementById(canvasId), {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        axis: 'y',
                        label: 'Amount USD',
                        backgroundColor: '#01803E',
                        data: customsData,
                    }
                ],
            },
            options: {
                maintainAspectRatio: false,
            }
        });

    }, 0);
}
