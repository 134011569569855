import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { PagedDataResponse$1, ShipmentSearchItem, User, FavoritedShipment, ShipmentSearchQuery, UserDataSources_$reflection, User_$reflection, Account_$reflection, PagedDataResponse$1_$reflection, ShipmentSearchItem_$reflection, ShipmentSearchQuery_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, bool_type, lambda_type, class_type, record_type, array_type, unit_type, union_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { render as render_2, update as update_1, State as State_1, init as init_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/ShipmentInfo.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { createObj, defaultOf, comparePrimitives, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { append, map as map_1, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { compare as compare_1, join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { Typeahead_render, Typeahead_init, Typeahead_setInputValue } from "../Components/Typeahead.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { iterate, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { getConsignees, getShippers, getLocations } from "../Api/DataSourceApi.fs.js";
import { SearchType_Entity, Location_LocationType, Location_Entity } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { export$, search } from "../Api/ShipmentApi.fs.js";
import { defaultShipmentSearchQuery } from "../Domain/Shipment.fs.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Guid.js";
import { DateTimeOffset_fromString, String_defaultValue, String_isNotNullOrWhiteSpace } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { empty, map as map_2, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { maxValue, minValue, toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { formatInt, toDateTimeOffsetInput } from "../Domain/Formatter.fs.js";
import { toNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(ShipmentSearchQuery, PreviousShipmentSearchQuery, ShipmentsDeferred, ExportShipments, Accounts, User, UserDataSources, ShipmentInfo) {
        super();
        this.ShipmentSearchQuery = ShipmentSearchQuery;
        this.PreviousShipmentSearchQuery = PreviousShipmentSearchQuery;
        this.ShipmentsDeferred = ShipmentsDeferred;
        this.ExportShipments = ExportShipments;
        this.Accounts = Accounts;
        this.User = User;
        this.UserDataSources = UserDataSources;
        this.ShipmentInfo = ShipmentInfo;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Shipments.State", [], State, () => [["ShipmentSearchQuery", ShipmentSearchQuery_$reflection()], ["PreviousShipmentSearchQuery", ShipmentSearchQuery_$reflection()], ["ShipmentsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["ExportShipments", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["UserDataSources", UserDataSources_$reflection()], ["ShipmentInfo", State_$reflection_1()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShipmentQueryChanged", "ShipmentPinned", "ShipmentUnpinned", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Shipments.Intent", [], Intent, () => [[["Item", ShipmentSearchQuery_$reflection()]], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item", class_type("System.Guid")]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateShipmentSearchQuery", "SearchShipments", "SetSelectedOrigin", "SetSelectedDestination", "SetSelectedPortOfLading", "SetSelectedPortOfDischarge", "SetSelectedConsignee", "SetSelectedShipper", "ClearSearch", "ExportShipments", "SetPageNumber", "ShipmentInfoMsg"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Shipments.Msg", [], Msg, () => [[["Item", lambda_type(ShipmentSearchQuery_$reflection(), ShipmentSearchQuery_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", Msg_$reflection_1()]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred)) {
        return true;
    }
    else {
        return equals_1(state.ShipmentsDeferred, new Deferred$1(1, []));
    }
}

function serviceSearch(state) {
    const services = sortBy((x) => x.Name, state.UserDataSources.Services, {
        Compare: comparePrimitives,
    });
    const matchValue = state.ShipmentSearchQuery.ModeOfTransport;
    switch (matchValue) {
        case "Air":
            return services.filter((x_2) => x_2.IsAir);
        case "Ground":
            return services.filter((x_3) => x_3.IsGround);
        case "Ocean":
            return services.filter((x_4) => x_4.IsOcean);
        case "Rail":
            return services.filter((x_5) => x_5.IsRail);
        default:
            return new Array(0);
    }
}

export function init(accounts, user, shipmentSearchQuery, userDataSources) {
    const setPortInputValue = (inputId, portName) => {
        if (!isNullOrWhiteSpace(portName)) {
            Typeahead_setInputValue(inputId, portName);
        }
    };
    setPortInputValue("shipment_port_of_lading", shipmentSearchQuery.PortOfLadingName);
    setPortInputValue("shipment_port_of_discharge", shipmentSearchQuery.PortOfDischargeName);
    setPortInputValue("shipment_final_destination", shipmentSearchQuery.FinalDestinationName);
    const patternInput = init_1();
    const shipmentInfoState = patternInput[0];
    return [new State(shipmentSearchQuery, shipmentSearchQuery, new Deferred$1(0, []), new Deferred$1(0, []), accounts, user, userDataSources, new State_1(true, shipmentInfoState.IsPinHidden, user.CanViewCO2Emissions, shipmentInfoState.Shipments, shipmentInfoState.ShipmentPinnedResponse, shipmentInfoState.ShipmentUnpinnedResponse)), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    }), Cmd_map((Item) => (new Msg(11, [Item])), patternInput[1])]))];
}

export function update(msg, state) {
    let bind$0040_2, bind$0040, bind$0040_1, bind$0040_3, bind$0040_4, bind$0040_5, x_3, bind$0040_6, x_5, bind$0040_7, PortOfDischargeGeoLocationId, x_7, bind$0040_8, x_9, x_10, bind$0040_9, bind$0040_10, bind$0040_11, matchValue, matchValue_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                let patternInput;
                if (msg.fields[0].fields[0].tag === 1) {
                    patternInput = [state.ShipmentInfo.Shipments, Alert_error(Alert_title("Search Shipments", Alert_text(msg.fields[0].fields[0].fields[0])))];
                }
                else {
                    const accountIds = map_1((dataSourceItem) => dataSourceItem.Id, state.Accounts);
                    patternInput = [msg.fields[0].fields[0].fields[0].Data, Cmd_batch(ofArray([Typeahead_init("shipment_place_of_receipt", (searchCriteria) => getLocations(new Location_Entity(0, []), new Location_LocationType(0, []), accountIds, searchCriteria), (Item_1) => (new Msg(2, [Item_1])), 500, 1), Typeahead_init("shipment_final_destination", (searchCriteria_1) => getLocations(new Location_Entity(0, []), new Location_LocationType(1, []), accountIds, searchCriteria_1), (Item_2) => (new Msg(3, [Item_2])), 500, 1), Typeahead_init("shipment_port_of_lading", (searchCriteria_2) => getLocations(new Location_Entity(0, []), new Location_LocationType(2, []), accountIds, searchCriteria_2), (Item_3) => (new Msg(4, [Item_3])), 500, 1), Typeahead_init("shipment_port_of_discharge", (searchCriteria_3) => getLocations(new Location_Entity(0, []), new Location_LocationType(3, []), accountIds, searchCriteria_3), (Item_4) => (new Msg(5, [Item_4])), 500, 1), Typeahead_init("shipment_shipper", (searchCriteria_4) => getShippers(new SearchType_Entity(0, []), accountIds, searchCriteria_4), (Item_5) => (new Msg(7, [Item_5])), 500, 1), Typeahead_init("shipment_consignee", (searchCriteria_5) => getConsignees(new SearchType_Entity(0, []), accountIds, searchCriteria_5), (Item_6) => (new Msg(6, [Item_6])), 500, 1)]))];
                }
                return [new State(state.ShipmentSearchQuery, msg.fields[1] ? state.PreviousShipmentSearchQuery : state.ShipmentSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportShipments, state.Accounts, state.User, state.UserDataSources, (bind$0040_2 = state.ShipmentInfo, new State_1(bind$0040_2.IsEditable, bind$0040_2.IsPinHidden, bind$0040_2.CanViewCO2Emissions, patternInput[0], bind$0040_2.ShipmentPinnedResponse, bind$0040_2.ShipmentUnpinnedResponse))), patternInput[1], new Intent(3, [])];
            }
            else if (equals_1(state.ShipmentsDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(3, [])];
            }
            else {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, new Deferred$1(1, []), state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040.TrackingNumber, bind$0040.ModeOfTransport, bind$0040.Service, bind$0040.PlaceOfReceiptGeoLocationId, bind$0040.PlaceOfReceiptName, bind$0040.FinalDestinationGeoLocationId, bind$0040.FinalDestinationName, bind$0040.PortOfLadingGeoLocationId, bind$0040.PortOfLadingName, bind$0040.PortOfDischargeGeoLocationId, bind$0040.PortOfDischargeName, map_1((x) => x.Id, state.Accounts), bind$0040.HasException, bind$0040.HasPotentialException, bind$0040.SelectedShipmentDate, bind$0040.ShipmentStatus, bind$0040.Shipper, bind$0040.Consignee, bind$0040.ShipmentFromDate, bind$0040.ShipmentToDate, bind$0040.Page, bind$0040.PageSize))) : ((bind$0040_1 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_1.TrackingNumber, bind$0040_1.ModeOfTransport, bind$0040_1.Service, bind$0040_1.PlaceOfReceiptGeoLocationId, bind$0040_1.PlaceOfReceiptName, bind$0040_1.FinalDestinationGeoLocationId, bind$0040_1.FinalDestinationName, bind$0040_1.PortOfLadingGeoLocationId, bind$0040_1.PortOfLadingName, bind$0040_1.PortOfDischargeGeoLocationId, bind$0040_1.PortOfDischargeName, map_1((x_1) => x_1.Id, state.Accounts), bind$0040_1.HasException, bind$0040_1.HasPotentialException, bind$0040_1.SelectedShipmentDate, bind$0040_1.ShipmentStatus, bind$0040_1.Shipper, bind$0040_1.Consignee, bind$0040_1.ShipmentFromDate, bind$0040_1.ShipmentToDate, 1, bind$0040_1.PageSize))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(3, [])];
            }
        case 8: {
            iterate((value_1) => {
                Typeahead_setInputValue(value_1, "");
            }, ofArray(["shipment_place_of_receipt", "shipment_final_destination", "shipment_port_of_lading", "shipment_port_of_discharge", "shipment_consignee", "shipment_shipper"]));
            const newState_1 = new State(defaultShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, (bind$0040_3 = state.ShipmentInfo, new State_1(bind$0040_3.IsEditable, bind$0040_3.IsPinHidden, bind$0040_3.CanViewCO2Emissions, new Array(0), bind$0040_3.ShipmentPinnedResponse, bind$0040_3.ShipmentUnpinnedResponse)));
            return [newState_1, singleton((dispatch) => {
                dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.ShipmentSearchQuery])];
        }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Track And Trace", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(3, [])];
            }
            else if (equals_1(state.ExportShipments, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(3, [])];
            }
            else {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, new Deferred$1(1, []), state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_4 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_4.TrackingNumber, bind$0040_4.ModeOfTransport, bind$0040_4.Service, bind$0040_4.PlaceOfReceiptGeoLocationId, bind$0040_4.PlaceOfReceiptName, bind$0040_4.FinalDestinationGeoLocationId, bind$0040_4.FinalDestinationName, bind$0040_4.PortOfLadingGeoLocationId, bind$0040_4.PortOfLadingName, bind$0040_4.PortOfDischargeGeoLocationId, bind$0040_4.PortOfDischargeName, map_1((x_2) => x_2.Id, state.Accounts), bind$0040_4.HasException, bind$0040_4.HasPotentialException, bind$0040_4.SelectedShipmentDate, bind$0040_4.ShipmentStatus, bind$0040_4.Shipper, bind$0040_4.Consignee, bind$0040_4.ShipmentFromDate, bind$0040_4.ShipmentToDate, bind$0040_4.Page, bind$0040_4.PageSize)), (arg_2) => (new Msg(9, [new AsyncMsg$1(1, [arg_2])]))), new Intent(3, [])];
            }
        case 2:
            return [new State((bind$0040_5 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_5.TrackingNumber, bind$0040_5.ModeOfTransport, bind$0040_5.Service, (x_3 = msg.fields[0], isNullOrWhiteSpace(x_3.Id) ? defaultOf() : parse(x_3.Id)), msg.fields[0].DisplayValue, bind$0040_5.FinalDestinationGeoLocationId, bind$0040_5.FinalDestinationName, bind$0040_5.PortOfLadingGeoLocationId, bind$0040_5.PortOfLadingName, bind$0040_5.PortOfDischargeGeoLocationId, bind$0040_5.PortOfDischargeName, bind$0040_5.AccountIds, bind$0040_5.HasException, bind$0040_5.HasPotentialException, bind$0040_5.SelectedShipmentDate, bind$0040_5.ShipmentStatus, bind$0040_5.Shipper, bind$0040_5.Consignee, bind$0040_5.ShipmentFromDate, bind$0040_5.ShipmentToDate, bind$0040_5.Page, bind$0040_5.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
        case 3:
            return [new State((bind$0040_6 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_6.TrackingNumber, bind$0040_6.ModeOfTransport, bind$0040_6.Service, bind$0040_6.PlaceOfReceiptGeoLocationId, bind$0040_6.PlaceOfReceiptName, (x_5 = msg.fields[0], isNullOrWhiteSpace(x_5.Id) ? defaultOf() : parse(x_5.Id)), msg.fields[0].DisplayValue, bind$0040_6.PortOfLadingGeoLocationId, bind$0040_6.PortOfLadingName, bind$0040_6.PortOfDischargeGeoLocationId, bind$0040_6.PortOfDischargeName, bind$0040_6.AccountIds, bind$0040_6.HasException, bind$0040_6.HasPotentialException, bind$0040_6.SelectedShipmentDate, bind$0040_6.ShipmentStatus, bind$0040_6.Shipper, bind$0040_6.Consignee, bind$0040_6.ShipmentFromDate, bind$0040_6.ShipmentToDate, bind$0040_6.Page, bind$0040_6.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
        case 4:
            return [new State((bind$0040_7 = state.ShipmentSearchQuery, (PortOfDischargeGeoLocationId = ((x_7 = msg.fields[0], isNullOrWhiteSpace(x_7.Id) ? defaultOf() : parse(x_7.Id))), new ShipmentSearchQuery(bind$0040_7.TrackingNumber, bind$0040_7.ModeOfTransport, bind$0040_7.Service, bind$0040_7.PlaceOfReceiptGeoLocationId, bind$0040_7.PlaceOfReceiptName, bind$0040_7.FinalDestinationGeoLocationId, bind$0040_7.FinalDestinationName, bind$0040_7.PortOfLadingGeoLocationId, msg.fields[0].DisplayValue, PortOfDischargeGeoLocationId, bind$0040_7.PortOfDischargeName, bind$0040_7.AccountIds, bind$0040_7.HasException, bind$0040_7.HasPotentialException, bind$0040_7.SelectedShipmentDate, bind$0040_7.ShipmentStatus, bind$0040_7.Shipper, bind$0040_7.Consignee, bind$0040_7.ShipmentFromDate, bind$0040_7.ShipmentToDate, bind$0040_7.Page, bind$0040_7.PageSize))), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
        case 5:
            return [new State((bind$0040_8 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_8.TrackingNumber, bind$0040_8.ModeOfTransport, bind$0040_8.Service, bind$0040_8.PlaceOfReceiptGeoLocationId, bind$0040_8.PlaceOfReceiptName, bind$0040_8.FinalDestinationGeoLocationId, bind$0040_8.FinalDestinationName, bind$0040_8.PortOfLadingGeoLocationId, bind$0040_8.PortOfLadingName, (x_9 = msg.fields[0], String_isNotNullOrWhiteSpace(x_9.Id) ? defaultOf() : parse(x_9.Id)), (x_10 = msg.fields[0], isNullOrWhiteSpace(x_10.DisplayValue) ? String_defaultValue() : x_10.DisplayValue), bind$0040_8.AccountIds, bind$0040_8.HasException, bind$0040_8.HasPotentialException, bind$0040_8.SelectedShipmentDate, bind$0040_8.ShipmentStatus, bind$0040_8.Shipper, bind$0040_8.Consignee, bind$0040_8.ShipmentFromDate, bind$0040_8.ShipmentToDate, bind$0040_8.Page, bind$0040_8.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
        case 6:
            return [new State((bind$0040_9 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_9.TrackingNumber, bind$0040_9.ModeOfTransport, bind$0040_9.Service, bind$0040_9.PlaceOfReceiptGeoLocationId, bind$0040_9.PlaceOfReceiptName, bind$0040_9.FinalDestinationGeoLocationId, bind$0040_9.FinalDestinationName, bind$0040_9.PortOfLadingGeoLocationId, bind$0040_9.PortOfLadingName, bind$0040_9.PortOfDischargeGeoLocationId, bind$0040_9.PortOfDischargeName, bind$0040_9.AccountIds, bind$0040_9.HasException, bind$0040_9.HasPotentialException, bind$0040_9.SelectedShipmentDate, bind$0040_9.ShipmentStatus, bind$0040_9.Shipper, msg.fields[0].DisplayValue, bind$0040_9.ShipmentFromDate, bind$0040_9.ShipmentToDate, bind$0040_9.Page, bind$0040_9.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
        case 7:
            return [new State((bind$0040_10 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_10.TrackingNumber, bind$0040_10.ModeOfTransport, bind$0040_10.Service, bind$0040_10.PlaceOfReceiptGeoLocationId, bind$0040_10.PlaceOfReceiptName, bind$0040_10.FinalDestinationGeoLocationId, bind$0040_10.FinalDestinationName, bind$0040_10.PortOfLadingGeoLocationId, bind$0040_10.PortOfLadingName, bind$0040_10.PortOfDischargeGeoLocationId, bind$0040_10.PortOfDischargeName, bind$0040_10.AccountIds, bind$0040_10.HasException, bind$0040_10.HasPotentialException, bind$0040_10.SelectedShipmentDate, bind$0040_10.ShipmentStatus, msg.fields[0].DisplayValue, bind$0040_10.Consignee, bind$0040_10.ShipmentFromDate, bind$0040_10.ShipmentToDate, bind$0040_10.Page, bind$0040_10.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
        case 10:
            return [new State(state.ShipmentSearchQuery, (bind$0040_11 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_11.TrackingNumber, bind$0040_11.ModeOfTransport, bind$0040_11.Service, bind$0040_11.PlaceOfReceiptGeoLocationId, bind$0040_11.PlaceOfReceiptName, bind$0040_11.FinalDestinationGeoLocationId, bind$0040_11.FinalDestinationName, bind$0040_11.PortOfLadingGeoLocationId, bind$0040_11.PortOfLadingName, bind$0040_11.PortOfDischargeGeoLocationId, bind$0040_11.PortOfDischargeName, bind$0040_11.AccountIds, bind$0040_11.HasException, bind$0040_11.HasPotentialException, bind$0040_11.SelectedShipmentDate, bind$0040_11.ShipmentStatus, bind$0040_11.Shipper, bind$0040_11.Consignee, bind$0040_11.ShipmentFromDate, bind$0040_11.ShipmentToDate, msg.fields[0], bind$0040_11.PageSize)), state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(3, [])];
        case 11: {
            const patternInput_1 = update_1(msg.fields[0], state.ShipmentInfo);
            const shipmentInfointent = patternInput_1[2];
            const shipmentInfoState = patternInput_1[0];
            let newState_9;
            switch (shipmentInfointent.tag) {
                case 1: {
                    const shipmentId = shipmentInfointent.fields[0];
                    let user;
                    const bind$0040_12 = state.User;
                    user = (new User(bind$0040_12.Id, bind$0040_12.UserTypeId, bind$0040_12.Email, bind$0040_12.DisplayName, bind$0040_12.IsActive, bind$0040_12.CanViewAirShipments, bind$0040_12.CanViewOceanShipments, bind$0040_12.CanViewGroundShipments, bind$0040_12.CanCreateBookings, bind$0040_12.CanViewBookings, bind$0040_12.CanExportSearchResults, bind$0040_12.CanViewInventory, bind$0040_12.CanViewAnalytics, bind$0040_12.CanViewRailShipments, bind$0040_12.CanViewCO2Emissions, bind$0040_12.CanViewBilling, bind$0040_12.CanViewShipments, bind$0040_12.CanExportBookings, bind$0040_12.CanExportInventory, bind$0040_12.CanExportBilling, bind$0040_12.CanExportDocuments, bind$0040_12.GlobalAccountId, bind$0040_12.GlobalAccountName, bind$0040_12.Accounts, append([new FavoritedShipment(shipmentId, shipmentInfointent.fields[1])], state.User.Shipments), bind$0040_12.PurchaseOrders, bind$0040_12.MaterialReceipts, bind$0040_12.PackingLists, bind$0040_12.UserPreferences));
                    newState_9 = (new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, (matchValue = state.ShipmentsDeferred, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? (new Deferred$1(2, [new FSharpResult$2(0, [new PagedDataResponse$1(matchValue.fields[0].fields[0].Page, matchValue.fields[0].fields[0].PageSize, matchValue.fields[0].fields[0].TotalCount, matchValue.fields[0].fields[0].NumberOfPages, matchValue.fields[0].fields[0].OrderBy, matchValue.fields[0].fields[0].OrderDirection, map_1((shipment) => {
                        if (shipment.Id === shipmentId) {
                            return new ShipmentSearchItem(shipment.Id, shipment.Customer, shipment.Shipper, shipment.Consignee, shipment.BookingNumber, shipment.ShipmentNumber, shipment.ShipmentStatus, shipment.CurrentLocation, shipment.PlaceOfReceipt, shipment.PortOfLading, shipment.PortOfDischarge, shipment.FinalDestination, shipment.BookingDate, shipment.StartDate, shipment.DueDate, shipment.DeliveredDate, shipment.EstimatedDeliveryDate, shipment.ServiceType, shipment.Service, shipment.TotalChargeableWeight, shipment.TotalChargeableWeightUnit, shipment.TotalPieces, shipment.TotalPiecesUnit, shipment.TotalEmissions, shipment.ModeOfTransport, shipment.ModeOfTransportIcon, shipment.HasDelays, shipment.HasPotentialDelays, true, shipment.LatestCompletedMilestoneName, shipment.HasVisibleSteps, shipment.ContainerCount);
                        }
                        else {
                            return shipment;
                        }
                    }, matchValue.fields[0].fields[0].Data))])])) : state.ShipmentsDeferred) : state.ShipmentsDeferred), state.ExportShipments, state.Accounts, user, state.UserDataSources, shipmentInfoState));
                    break;
                }
                case 2: {
                    const shipmentId_1 = shipmentInfointent.fields[0];
                    let user_1;
                    const bind$0040_13 = state.User;
                    user_1 = (new User(bind$0040_13.Id, bind$0040_13.UserTypeId, bind$0040_13.Email, bind$0040_13.DisplayName, bind$0040_13.IsActive, bind$0040_13.CanViewAirShipments, bind$0040_13.CanViewOceanShipments, bind$0040_13.CanViewGroundShipments, bind$0040_13.CanCreateBookings, bind$0040_13.CanViewBookings, bind$0040_13.CanExportSearchResults, bind$0040_13.CanViewInventory, bind$0040_13.CanViewAnalytics, bind$0040_13.CanViewRailShipments, bind$0040_13.CanViewCO2Emissions, bind$0040_13.CanViewBilling, bind$0040_13.CanViewShipments, bind$0040_13.CanExportBookings, bind$0040_13.CanExportInventory, bind$0040_13.CanExportBilling, bind$0040_13.CanExportDocuments, bind$0040_13.GlobalAccountId, bind$0040_13.GlobalAccountName, bind$0040_13.Accounts, state.User.Shipments.filter((userShipment) => (userShipment.ShipmentId !== shipmentId_1)), bind$0040_13.PurchaseOrders, bind$0040_13.MaterialReceipts, bind$0040_13.PackingLists, bind$0040_13.UserPreferences));
                    newState_9 = (new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, (matchValue_1 = state.ShipmentsDeferred, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new Deferred$1(2, [new FSharpResult$2(0, [new PagedDataResponse$1(matchValue_1.fields[0].fields[0].Page, matchValue_1.fields[0].fields[0].PageSize, matchValue_1.fields[0].fields[0].TotalCount, matchValue_1.fields[0].fields[0].NumberOfPages, matchValue_1.fields[0].fields[0].OrderBy, matchValue_1.fields[0].fields[0].OrderDirection, map_1((shipment_1) => {
                        if (shipment_1.Id === shipmentId_1) {
                            return new ShipmentSearchItem(shipment_1.Id, shipment_1.Customer, shipment_1.Shipper, shipment_1.Consignee, shipment_1.BookingNumber, shipment_1.ShipmentNumber, shipment_1.ShipmentStatus, shipment_1.CurrentLocation, shipment_1.PlaceOfReceipt, shipment_1.PortOfLading, shipment_1.PortOfDischarge, shipment_1.FinalDestination, shipment_1.BookingDate, shipment_1.StartDate, shipment_1.DueDate, shipment_1.DeliveredDate, shipment_1.EstimatedDeliveryDate, shipment_1.ServiceType, shipment_1.Service, shipment_1.TotalChargeableWeight, shipment_1.TotalChargeableWeightUnit, shipment_1.TotalPieces, shipment_1.TotalPiecesUnit, shipment_1.TotalEmissions, shipment_1.ModeOfTransport, shipment_1.ModeOfTransportIcon, shipment_1.HasDelays, shipment_1.HasPotentialDelays, false, shipment_1.LatestCompletedMilestoneName, shipment_1.HasVisibleSteps, shipment_1.ContainerCount);
                        }
                        else {
                            return shipment_1;
                        }
                    }, matchValue_1.fields[0].fields[0].Data))])])) : state.ShipmentsDeferred) : state.ShipmentsDeferred), state.ExportShipments, state.Accounts, user_1, state.UserDataSources, shipmentInfoState));
                    break;
                }
                default:
                    newState_9 = state;
            }
            const intent = (shipmentInfointent.tag === 1) ? (new Intent(1, [shipmentInfointent.fields[0], shipmentInfointent.fields[1]])) : ((shipmentInfointent.tag === 2) ? (new Intent(2, [shipmentInfointent.fields[0]])) : (new Intent(3, [])));
            return [newState_9, Cmd_map((Item_11) => (new Msg(11, [Item_11])), patternInput_1[1]), intent];
        }
        default:
            return [new State(msg.fields[0](state.ShipmentSearchQuery), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources, state.ShipmentInfo), Cmd_none(), new Intent(3, [])];
    }
}

export function render(state, dispatch) {
    let elems_26, elems_25, elems_1, elems, value_26, elems_4, elems_3, value_43, elems_2, elems_7, elems_5, elems_6, elems_8, elems_9, elems_10, elems_11, elems_14, elems_13, value_137, elems_12, elems_15, value_169, elems_16, value_192, elems_17, elems_18, elems_21, elems_20, value_225, elems_19, elems_24, elems_23, value_253, elems_22, elems_35, elems_34, elems_33, elems_27, elems_28, matchValue_8, elems_32, elems_39, elems_38;
    let searchControls;
    const labelCss = ofArray(["is-block", "mb-1", "has-text-weight-semibold"]);
    const inputCss = ofArray(["input", "is-small", "is-fullwidth"]);
    const selectContainerCss = ofArray(["select", "is-small", "is-fullwidth"]);
    searchControls = createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", join(" ", ["box", "is-flex", "is-flex-direction-column"])], ["style", {
        minWidth: 360 + "px",
    }], (elems_26 = [createElement("div", createObj(ofArray([["style", {
        overflowX: "auto",
    }], (elems_25 = [createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems = [createElement("label", {
        className: join(" ", ["has-text-weight-semibold"]),
        for: "trackingNumber",
        children: "Tracking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "trackingNumber"], ["name", "trackingNumber"], ["type", "text"], ["maxLength", 50], ["placeholder", "Tracking Number..."], (value_26 = state.ShipmentSearchQuery.TrackingNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_26)) {
            e_1.value = value_26;
        }
    }]), ["onChange", (ev) => {
        const value_28 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new ShipmentSearchQuery(value_28, query.ModeOfTransport, query.Service, query.PlaceOfReceiptGeoLocationId, query.PlaceOfReceiptName, query.FinalDestinationGeoLocationId, query.FinalDestinationName, query.PortOfLadingGeoLocationId, query.PortOfLadingName, query.PortOfDischargeGeoLocationId, query.PortOfDischargeName, query.AccountIds, query.HasException, query.HasPotentialException, query.SelectedShipmentDate, query.ShipmentStatus, query.Shipper, query.Consignee, query.ShipmentFromDate, query.ShipmentToDate, query.Page, query.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_4 = [createElement("label", {
        className: join(" ", labelCss),
        for: "status",
        children: "Status",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_3 = [createElement("select", createObj(ofArray([["className", join(" ", [])], ["id", "status"], ["name", "status"], (value_43 = state.ShipmentSearchQuery.ShipmentStatus, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals_1(e_2.value, value_43)) {
            e_2.value = value_43;
        }
    }]), (elems_2 = toList(delay(() => append_1(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((status) => createElement("option", {
        value: status.ShipmentStatus,
        children: status.ShipmentStatus,
    }), state.UserDataSources.ShipmentStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new ShipmentSearchQuery(query_1.TrackingNumber, query_1.ModeOfTransport, query_1.Service, query_1.PlaceOfReceiptGeoLocationId, query_1.PlaceOfReceiptName, query_1.FinalDestinationGeoLocationId, query_1.FinalDestinationName, query_1.PortOfLadingGeoLocationId, query_1.PortOfLadingName, query_1.PortOfDischargeGeoLocationId, query_1.PortOfDischargeName, query_1.AccountIds, query_1.HasException, query_1.HasPotentialException, query_1.SelectedShipmentDate, element.value, query_1.Shipper, query_1.Consignee, query_1.ShipmentFromDate, query_1.ShipmentToDate, query_1.Page, query_1.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems_5 = [createElement("input", {
        className: join(" ", ["is-clickable"]),
        id: "Delayed",
        name: "Delayed",
        type: "checkbox",
        checked: state.ShipmentSearchQuery.HasException,
        onChange: (ev_1) => {
            const value_67 = ev_1.target.checked;
            dispatch(new Msg(0, [(query_2) => (new ShipmentSearchQuery(query_2.TrackingNumber, query_2.ModeOfTransport, query_2.Service, query_2.PlaceOfReceiptGeoLocationId, query_2.PlaceOfReceiptName, query_2.FinalDestinationGeoLocationId, query_2.FinalDestinationName, query_2.PortOfLadingGeoLocationId, query_2.PortOfLadingName, query_2.PortOfDischargeGeoLocationId, query_2.PortOfDischargeName, query_2.AccountIds, value_67, query_2.HasPotentialException, query_2.SelectedShipmentDate, query_2.ShipmentStatus, query_2.Shipper, query_2.Consignee, query_2.ShipmentFromDate, query_2.ShipmentToDate, query_2.Page, query_2.PageSize))]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "Delayed",
        children: "Delayed",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_6 = [createElement("input", {
        className: join(" ", ["is-clickable"]),
        id: "PotentialDelays",
        name: "PotentialDelays",
        type: "checkbox",
        checked: state.ShipmentSearchQuery.HasPotentialException,
        onChange: (ev_2) => {
            const value_84 = ev_2.target.checked;
            dispatch(new Msg(0, [(query_3) => (new ShipmentSearchQuery(query_3.TrackingNumber, query_3.ModeOfTransport, query_3.Service, query_3.PlaceOfReceiptGeoLocationId, query_3.PlaceOfReceiptName, query_3.FinalDestinationGeoLocationId, query_3.FinalDestinationName, query_3.PortOfLadingGeoLocationId, query_3.PortOfLadingName, query_3.PortOfDischargeGeoLocationId, query_3.PortOfDischargeName, query_3.AccountIds, query_3.HasException, value_84, query_3.SelectedShipmentDate, query_3.ShipmentStatus, query_3.Shipper, query_3.Consignee, query_3.ShipmentFromDate, query_3.ShipmentToDate, query_3.Page, query_3.PageSize))]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "PotentialDelays",
        children: "Potential Delays",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            keyPress.preventDefault();
        }
    }], (elems_8 = [createElement("label", {
        className: join(" ", labelCss),
        for: "origin",
        children: "Place Of Receipt",
    }), Typeahead_render("shipment_place_of_receipt", "Place Of Receipt")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_1) => {
        if (keyPress_1.key === "Enter") {
            keyPress_1.preventDefault();
        }
    }], (elems_9 = [createElement("label", {
        className: join(" ", labelCss),
        for: "portOfLading",
        children: "Port Of Lading",
    }), Typeahead_render("shipment_port_of_lading", "Port Of Lading")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_2) => {
        if (keyPress_2.key === "Enter") {
            keyPress_2.preventDefault();
        }
    }], (elems_10 = [createElement("label", {
        className: join(" ", labelCss),
        for: "portOfDischarge",
        children: "Port Of Discharge",
    }), Typeahead_render("shipment_port_of_discharge", "Port Of Discharge")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_3) => {
        if (keyPress_3.key === "Enter") {
            keyPress_3.preventDefault();
        }
    }], (elems_11 = [createElement("label", {
        className: join(" ", labelCss),
        for: "destination",
        children: "Final Destination",
    }), Typeahead_render("shipment_final_destination", "Final Destination")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_14 = [createElement("label", {
        className: join(" ", labelCss),
        for: "shipmentDate",
        children: "Shipment Date",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_13 = [createElement("select", createObj(ofArray([["className", join(" ", [])], ["id", "shipmentDate"], ["name", "shipmentDate"], (value_137 = state.ShipmentSearchQuery.SelectedShipmentDate, ["ref", (e_4) => {
        if (!(e_4 == null) && !equals_1(e_4.value, value_137)) {
            e_4.value = value_137;
        }
    }]), (elems_12 = toList(delay(() => append_1(singleton_1(createElement("option", {
        value: "",
        children: "Select...",
    })), delay(() => map_2((shipmentDate) => createElement("option", {
        value: shipmentDate.ShipmentDate,
        children: shipmentDate.ShipmentDate,
    }), state.UserDataSources.ShipmentDates))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_4) => (isNullOrWhiteSpace(element_1.value) ? (new ShipmentSearchQuery(query_4.TrackingNumber, query_4.ModeOfTransport, query_4.Service, query_4.PlaceOfReceiptGeoLocationId, query_4.PlaceOfReceiptName, query_4.FinalDestinationGeoLocationId, query_4.FinalDestinationName, query_4.PortOfLadingGeoLocationId, query_4.PortOfLadingName, query_4.PortOfDischargeGeoLocationId, query_4.PortOfDischargeName, query_4.AccountIds, query_4.HasException, query_4.HasPotentialException, element_1.value, query_4.ShipmentStatus, query_4.Shipper, query_4.Consignee, defaultOf(), defaultOf(), query_4.Page, query_4.PageSize)) : (new ShipmentSearchQuery(query_4.TrackingNumber, query_4.ModeOfTransport, query_4.Service, query_4.PlaceOfReceiptGeoLocationId, query_4.PlaceOfReceiptName, query_4.FinalDestinationGeoLocationId, query_4.FinalDestinationName, query_4.PortOfLadingGeoLocationId, query_4.PortOfLadingName, query_4.PortOfDischargeGeoLocationId, query_4.PortOfDischargeName, query_4.AccountIds, query_4.HasException, query_4.HasPotentialException, element_1.value, query_4.ShipmentStatus, query_4.Shipper, query_4.Consignee, query_4.ShipmentFromDate, query_4.ShipmentToDate, query_4.Page, query_4.PageSize)))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_15 = [createElement("label", {
        className: join(" ", labelCss),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "fromDate"], ["name", "fromDate"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.SelectedShipmentDate)], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_169 = toDateTimeOffsetInput(state.ShipmentSearchQuery.ShipmentFromDate), ["ref", (e_6) => {
        if (!(e_6 == null) && !equals_1(e_6.value, value_169)) {
            e_6.value = value_169;
        }
    }]), ["onChange", (e_7) => {
        const element_2 = e_7.target;
        dispatch(new Msg(0, [(query_5) => (new ShipmentSearchQuery(query_5.TrackingNumber, query_5.ModeOfTransport, query_5.Service, query_5.PlaceOfReceiptGeoLocationId, query_5.PlaceOfReceiptName, query_5.FinalDestinationGeoLocationId, query_5.FinalDestinationName, query_5.PortOfLadingGeoLocationId, query_5.PortOfLadingName, query_5.PortOfDischargeGeoLocationId, query_5.PortOfDischargeName, query_5.AccountIds, query_5.HasException, query_5.HasPotentialException, query_5.SelectedShipmentDate, query_5.ShipmentStatus, query_5.Shipper, query_5.Consignee, isNullOrWhiteSpace(element_2.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_2.value)), query_5.ShipmentToDate, query_5.Page, query_5.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_16 = [createElement("label", {
        className: join(" ", labelCss),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "toDate"], ["name", "toDate"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.SelectedShipmentDate)], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_192 = toDateTimeOffsetInput(state.ShipmentSearchQuery.ShipmentToDate), ["ref", (e_8) => {
        if (!(e_8 == null) && !equals_1(e_8.value, value_192)) {
            e_8.value = value_192;
        }
    }]), ["onChange", (e_9) => {
        const element_3 = e_9.target;
        dispatch(new Msg(0, [(query_6) => (new ShipmentSearchQuery(query_6.TrackingNumber, query_6.ModeOfTransport, query_6.Service, query_6.PlaceOfReceiptGeoLocationId, query_6.PlaceOfReceiptName, query_6.FinalDestinationGeoLocationId, query_6.FinalDestinationName, query_6.PortOfLadingGeoLocationId, query_6.PortOfLadingName, query_6.PortOfDischargeGeoLocationId, query_6.PortOfDischargeName, query_6.AccountIds, query_6.HasException, query_6.HasPotentialException, query_6.SelectedShipmentDate, query_6.ShipmentStatus, query_6.Shipper, query_6.Consignee, query_6.ShipmentFromDate, isNullOrWhiteSpace(element_3.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_3.value)), query_6.Page, query_6.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_4) => {
        if (keyPress_4.key === "Enter") {
            keyPress_4.preventDefault();
        }
    }], (elems_17 = [createElement("label", {
        className: join(" ", labelCss),
        for: "shipper",
        children: "Shipper",
    }), Typeahead_render("shipment_shipper", "Shipper")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_5) => {
        if (keyPress_5.key === "Enter") {
            keyPress_5.preventDefault();
        }
    }], (elems_18 = [createElement("label", {
        className: join(" ", labelCss),
        for: "consignee",
        children: "Consignee",
    }), Typeahead_render("shipment_consignee", "Consignee")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_21 = [createElement("label", {
        className: join(" ", labelCss),
        for: "mot",
        children: "Mode Of Transport",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_20 = [createElement("select", createObj(ofArray([["className", join(" ", inputCss)], ["id", "mot"], ["name", "mot"], (value_225 = state.ShipmentSearchQuery.ModeOfTransport, ["ref", (e_10) => {
        if (!(e_10 == null) && !equals_1(e_10.value, value_225)) {
            e_10.value = value_225;
        }
    }]), (elems_19 = toList(delay(() => append_1(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((mot) => createElement("option", {
        value: mot.ModeOfTransport,
        children: mot.ModeOfTransport,
    }), state.UserDataSources.ModesOfTransport))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))]), ["onChange", (e_11) => {
        const element_4 = e_11.target;
        dispatch(new Msg(0, [(query_7) => (new ShipmentSearchQuery(query_7.TrackingNumber, element_4.value, "", query_7.PlaceOfReceiptGeoLocationId, query_7.PlaceOfReceiptName, query_7.FinalDestinationGeoLocationId, query_7.FinalDestinationName, query_7.PortOfLadingGeoLocationId, query_7.PortOfLadingName, query_7.PortOfDischargeGeoLocationId, query_7.PortOfDischargeName, query_7.AccountIds, query_7.HasException, query_7.HasPotentialException, query_7.SelectedShipmentDate, query_7.ShipmentStatus, query_7.Shipper, query_7.Consignee, query_7.ShipmentFromDate, query_7.ShipmentToDate, query_7.Page, query_7.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_24 = [createElement("label", {
        className: join(" ", labelCss),
        for: "service",
        children: "Service",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_23 = [createElement("select", createObj(ofArray([["className", join(" ", inputCss)], ["id", "service"], ["name", "service"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.ModeOfTransport) ? true : (compare_1(state.ShipmentSearchQuery.ModeOfTransport, "Any", 5) === 0)], (value_253 = state.ShipmentSearchQuery.Service, ["ref", (e_12) => {
        if (!(e_12 == null) && !equals_1(e_12.value, value_253)) {
            e_12.value = value_253;
        }
    }]), (elems_22 = toList(delay(() => append_1(singleton_1(createElement("option", {
        value: "",
        children: "Any",
    })), delay(() => map_2((service) => createElement("option", {
        value: service.Name,
        children: service.Name,
    }), serviceSearch(state)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))]), ["onChange", (e_13) => {
        const element_5 = e_13.target;
        dispatch(new Msg(0, [(query_8) => (new ShipmentSearchQuery(query_8.TrackingNumber, query_8.ModeOfTransport, element_5.value, query_8.PlaceOfReceiptGeoLocationId, query_8.PlaceOfReceiptName, query_8.FinalDestinationGeoLocationId, query_8.FinalDestinationName, query_8.PortOfLadingGeoLocationId, query_8.PortOfLadingName, query_8.PortOfDischargeGeoLocationId, query_8.PortOfDischargeName, query_8.AccountIds, query_8.HasException, query_8.HasPotentialException, query_8.SelectedShipmentDate, query_8.ShipmentStatus, query_8.Shipper, query_8.Consignee, query_8.ShipmentFromDate, query_8.ShipmentToDate, query_8.Page, query_8.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])]))), createElement("div", {
        className: "is-flex-grow-1",
    }), createElement("hr", {
        className: "my-4",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append_1(singleton_1("block"), delay(() => append_1(singleton_1("button"), delay(() => append_1(singleton_1("is-small"), delay(() => append_1(singleton_1("is-primary"), delay(() => append_1(singleton_1("is-fullwidth"), delay(() => (equals_1(state.ShipmentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append_1(singleton_1("block"), delay(() => append_1(singleton_1("button"), delay(() => append_1(singleton_1("is-small"), delay(() => append_1(singleton_1("is-fullwidth"), delay(() => (equals_1(state.ShipmentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))),
        type: "button",
        onClick: (_arg) => {
            dispatch(new Msg(8, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])));
    const header = createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_35 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_34 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_33 = [createElement("div", createObj(ofArray([["className", "column"], (elems_27 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Shipments",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_28 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold"]),
        children: (matchValue_8 = state.ShipmentsDeferred, (matchValue_8.tag === 1) ? "Searching..." : ((matchValue_8.tag === 2) ? ((matchValue_8.fields[0].tag === 0) ? ((compare(matchValue_8.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue_8.fields[0].fields[0].TotalCount))} shipments found | showing page ${formatInt(matchValue_8.fields[0].fields[0].Page)} of ${formatInt(matchValue_8.fields[0].fields[0].NumberOfPages)}`) : "0 shipments found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))), createElement("div", createObj(ofArray([["className", "column is-narrow"], (elems_32 = toList(delay(() => {
        let elems_29;
        return append_1(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(10, [state.PreviousShipmentSearchQuery.Page - 1]));
            }
        }], (elems_29 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: join(" ", ["mx-1"]),
        }), createElement("span", {
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))), delay(() => {
            let elems_30;
            return append_1(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(10, [state.PreviousShipmentSearchQuery.Page + 1]));
                }
            }], (elems_30 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: join(" ", ["mx-1"]),
            }), createElement("span", {
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))), delay(() => {
                let elems_31;
                return state.User.CanExportDocuments ? singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", equals_1(state.ExportShipments, new Deferred$1(1, [])) ? true : isExportDisabled(state)], ["onClick", (_arg_3) => {
                    dispatch(new Msg(9, [new AsyncMsg$1(0, [])]));
                }], (elems_31 = [createElement("span", {
                    className: join(" ", ["material-icons"]),
                    children: "download",
                }), createElement("span", {
                    className: join(" ", ["mx-1"]),
                }), createElement("span", {
                    children: (state.ExportShipments.tag === 1) ? "Exporting..." : "Export",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))) : empty();
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])));
    let searchResult;
    const matchValue_10 = state.ShipmentsDeferred;
    searchResult = ((matchValue_10.tag === 1) ? render_1() : ((matchValue_10.tag === 2) ? ((matchValue_10.fields[0].tag === 0) ? render_2(state.ShipmentInfo, (arg_1) => {
        dispatch(new Msg(11, [arg_1]));
    }) : defaultOf_1()) : defaultOf_1()));
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_39 = [header, createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_38 = [createElement("div", {
            className: join(" ", ["column", "is-narrow", "full-height-container"]),
            children: Interop_reactApi.Children.toArray([searchControls]),
        }), createElement("div", {
            className: join(" ", ["column", "full-height-container"]),
            children: Interop_reactApi.Children.toArray([searchResult]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))]))));
    }
}

