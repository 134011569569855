import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ReportAccounts_$reflection, AnalyticsReport_$reflection, AnalyticsReportPage_$reflection, AnalyticsReportToken_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, class_type, unit_type, option_type, union_type, string_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { exists, add, tryFind as tryFind_1, remove, tryFindKey, empty } from "../fable_modules/fable-library.4.10.0/Map.js";
import { createObj, equals, comparePrimitives } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { toArray, defaultArg, map as map_1, defaultArgWith, bind } from "../fable_modules/fable-library.4.10.0/Option.js";
import { map, tryFind, sortBy, tryHead } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getAnalyticsReports, getEmbedToken } from "../Api/PowerBIApi.fs.js";
import { PowerBI_render } from "../Components/PowerBI.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { map as map_2, exists as exists_1, empty as empty_1, append, singleton as singleton_1, collect, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";

export class State extends Record {
    constructor(Accounts, AccessTokenDeferred, SelectedReportPage, AvailableReports, SelectedReports, ReportAccounts) {
        super();
        this.Accounts = Accounts;
        this.AccessTokenDeferred = AccessTokenDeferred;
        this.SelectedReportPage = SelectedReportPage;
        this.AvailableReports = AvailableReports;
        this.SelectedReports = SelectedReports;
        this.ReportAccounts = ReportAccounts;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.AnalyticsDashboard.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["AccessTokenDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AnalyticsReportToken_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AnalyticsReportToken_$reflection()]], [["ErrorValue", string_type]]]))], ["SelectedReportPage", option_type(AnalyticsReportPage_$reflection())], ["AvailableReports", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(AnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))], ["SelectedReports", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, unit_type])], ["ReportAccounts", ReportAccounts_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "AvailableReports"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.AnalyticsDashboard.Intent", [], Intent, () => [[], [["Item", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(AnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetEmbedReportTokenAsync", "SelectReportPage", "GetUserAnalyticReportsAsync", "SetSelectedReport"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.AnalyticsDashboard.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AnalyticsReportToken_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AnalyticsReportToken_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AnalyticsReportPage_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AnalyticsReport_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(AnalyticsReport_$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

function toUserAnalyticReports(state) {
    const matchValue = state.AvailableReports;
    let matchResult, analyticReports;
    switch (matchValue.tag) {
        case 2: {
            if (matchValue.fields[0].tag === 0) {
                matchResult = 1;
                analyticReports = matchValue.fields[0].fields[0];
            }
            else {
                matchResult = 0;
            }
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return new Array(0);
        default:
            return analyticReports;
    }
}

export function init(accounts, reportAccounts) {
    return [new State(accounts, new Deferred$1(0, []), void 0, new Deferred$1(0, []), empty({
        Compare: comparePrimitives,
    }), reportAccounts), (accounts.length === 0) ? Cmd_none() : singleton((dispatch) => {
        dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    let selectedReportPage, matchValue_5, selectedReportId, selectedReportPage_1, analyticReports, firstReport_1, firstReport_2, firstReportPage_1, matchValue, matchValue_1, copyOfStruct, copyOfStruct_1;
    switch (msg.tag) {
        case 0:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedReportPage, state.AvailableReports, state.SelectedReports, state.ReportAccounts), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((selectedReportPage = ((matchValue_5 = tryFindKey((key, _arg) => true, state.SelectedReports), (matchValue_5 == null) ? void 0 : ((selectedReportId = matchValue_5, bind((firstReport) => tryHead(sortBy((x_4) => x_4.Ordinal, firstReport.Pages, {
                    Compare: comparePrimitives,
                })), tryFind((x_3) => (x_3.Id === selectedReportId), toUserAnalyticReports(state))))))), (selectedReportPage != null) ? ((selectedReportPage_1 = selectedReportPage, singleton((dispatch) => {
                    dispatch(new Msg(1, [selectedReportPage_1]));
                }))) : Cmd_none())), new Intent(0, [])];
            }
            else if (state.AccessTokenDeferred.tag === 0) {
                return [new State(state.Accounts, new Deferred$1(1, []), state.SelectedReportPage, state.AvailableReports, state.SelectedReports, state.ReportAccounts), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, getEmbedToken, map((x_2) => x_2.Id, state.Accounts), (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
            }
            else {
                return [state, Cmd_none(), new Intent(0, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.AccessTokenDeferred, state.SelectedReportPage, new Deferred$1(2, [msg.fields[0].fields[0]]), state.SelectedReports, state.ReportAccounts), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Analytic Reports", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((analyticReports = msg.fields[0].fields[0].fields[0], (analyticReports.length > 0) ? ((firstReport_1 = tryHead(sortBy((x_7) => x_7.Ordinal, analyticReports, {
                    Compare: comparePrimitives,
                })), Cmd_batch(ofArray([(firstReport_1 != null) ? ((firstReport_2 = firstReport_1, singleton((dispatch_1) => {
                    dispatch_1(new Msg(3, [firstReport_2.Id]));
                }))) : Cmd_none(), singleton((dispatch_2) => {
                    dispatch_2(new Msg(0, [new AsyncMsg$1(0, [])]));
                })])))) : Cmd_none())), new Intent(1, [new Deferred$1(2, [msg.fields[0].fields[0]])])];
            }
            else if (equals(state.AvailableReports, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.Accounts, state.AccessTokenDeferred, state.SelectedReportPage, new Deferred$1(1, []), state.SelectedReports, state.ReportAccounts), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, getAnalyticsReports, map((x_6) => x_6.Id, state.Accounts), (arg_2) => (new Msg(2, [new AsyncMsg$1(1, [arg_2])]))), new Intent(0, [])];
            }
        case 3: {
            const selectedReports = defaultArgWith(map_1(() => remove(msg.fields[0], state.SelectedReports), tryFind_1(msg.fields[0], state.SelectedReports)), () => add(msg.fields[0], void 0, state.SelectedReports));
            let firstReportPage;
            const matchValue_6 = state.AvailableReports;
            let matchResult, availableReports_3;
            if (matchValue_6.tag === 2) {
                if (matchValue_6.fields[0].tag === 0) {
                    if (matchValue_6.fields[0].fields[0].length > 0) {
                        matchResult = 0;
                        availableReports_3 = matchValue_6.fields[0].fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    firstReportPage = bind((firstReport_3) => tryHead(sortBy((x_10) => x_10.Ordinal, firstReport_3.Pages, {
                        Compare: comparePrimitives,
                    })), tryFind((x_9) => (x_9.Id === msg.fields[0]), availableReports_3));
                    break;
                }
                default:
                    firstReportPage = void 0;
            }
            return [new State(state.Accounts, state.AccessTokenDeferred, state.SelectedReportPage, state.AvailableReports, selectedReports, state.ReportAccounts), (firstReportPage != null) ? ((firstReportPage_1 = firstReportPage, singleton((dispatch_3) => {
                dispatch_3(new Msg(1, [firstReportPage_1]));
            }))) : Cmd_none(), new Intent(0, [])];
        }
        default:
            return [new State(state.Accounts, state.AccessTokenDeferred, msg.fields[0], state.AvailableReports, state.SelectedReports, state.ReportAccounts), (matchValue = state.AccessTokenDeferred, (matchValue_1 = state.AvailableReports, (matchValue.tag === 2) ? ((matchValue_1.tag === 2) ? ((copyOfStruct = matchValue.fields[0], (copyOfStruct.tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(copyOfStruct.fields[0]))) : ((copyOfStruct_1 = matchValue_1.fields[0], (copyOfStruct_1.tag === 1) ? Alert_error(Alert_title("Analytics Dashboard", Alert_text(copyOfStruct_1.fields[0]))) : PowerBI_render(msg.fields[0].ReportId, msg.fields[0].ReportId, defaultArg(map_1((x_1) => x_1.IsGlobal, tryFind((x) => (x.Id === msg.fields[0].ReportId), copyOfStruct_1.fields[0])), false), copyOfStruct.fields[0].AccessToken, state.ReportAccounts.AccountExternalIds, state.ReportAccounts.GlobalAccountExternalId, msg.fields[0].Name))))) : Cmd_none()) : Cmd_none())), new Intent(0, [])];
    }
}

export function render(state, dispatch) {
    let elems_7, elems_5, elems_4, elems_6;
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        const matchValue = state.AvailableReports;
        let matchResult, availableReports_1;
        switch (matchValue.tag) {
            case 1: {
                matchResult = 1;
                break;
            }
            case 2: {
                if (matchValue.fields[0].tag === 0) {
                    if (matchValue.fields[0].fields[0].length > 0) {
                        matchResult = 2;
                        availableReports_1 = matchValue.fields[0].fields[0];
                    }
                    else {
                        matchResult = 3;
                    }
                }
                else {
                    matchResult = 3;
                }
                break;
            }
            default:
                matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return defaultOf();
            case 1:
                return render_1();
            case 2:
                return createElement("div", createObj(ofArray([["className", "columns"], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_5 = [createElement("aside", createObj(ofArray([["className", "menu"], (elems_4 = toList(delay(() => collect((report) => {
                    let children_4, elems_1, elems, elems_3, elems_2;
                    const isReportSelected = exists((key_5, _arg) => (key_5 === report.Id), state.SelectedReports);
                    const page = tryHead(sortBy((x) => x.Ordinal, report.Pages, {
                        Compare: comparePrimitives,
                    }));
                    return singleton_1((children_4 = ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-flex-wrap-wrap"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems = [createElement("span", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["is-flex", "is-justify-content-flex-right", "material-icons", "is-clickable", "is-panel-collapsed", "mr-2"])]), delay(() => append(singleton_1(["children", isReportSelected ? "expand_less" : "expand_more"]), delay(() => append(singleton_1(["onClick", (_arg_1) => {
                        dispatch(new Msg(3, [report.Id]));
                    }]), delay(() => {
                        let matchValue_1, page_1;
                        return append((matchValue_1 = page, (matchValue_1 == null) ? (empty_1()) : ((page_1 = matchValue_1, append(singleton_1(["data-report-id", page_1.ReportId]), delay(() => singleton_1(["data-page-name", page_1.Name])))))), delay(() => singleton_1(["data-navigation-header", "My Analytics"])));
                    })))))))))), createElement("p", {
                        className: join(" ", ["menu-label", "m-0"]),
                        children: report.Name,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("mb-2"), delay(() => append(singleton_1("mx-4"), delay(() => (!isReportSelected ? singleton_1("is-hidden") : empty_1()))))))))], ["id", "expansion_panel_1"], (elems_3 = [createElement("ul", createObj(ofArray([["className", "menu-list"], (elems_2 = toList(delay(() => collect((page_2) => {
                        let children_2;
                        const isPageSelected = exists_1((reportPage) => {
                            if (reportPage.Name === page_2.Name) {
                                return reportPage.ReportId === report.Id;
                            }
                            else {
                                return false;
                            }
                        }, toArray(state.SelectedReportPage));
                        return singleton_1((children_2 = singleton(createElement("a", {
                            "data-report-id": page_2.ReportId,
                            "data-page-name": page_2.Name,
                            "data-navigation-header": "My Analytics",
                            className: join(" ", toList(delay(() => append(singleton_1("is-size-7"), delay(() => (isPageSelected ? singleton_1("is-active") : singleton_1(""))))))),
                            children: page_2.Name,
                            onClick: (_arg_2) => {
                                dispatch(new Msg(1, [page_2]));
                            },
                        })), createElement("li", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                        })));
                    }, sortBy((s) => s.Ordinal, report.Pages, {
                        Compare: comparePrimitives,
                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })));
                }, sortBy((s_1) => s_1.Ordinal, availableReports_1, {
                    Compare: comparePrimitives,
                })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "mb-2"])], ["style", {
                    height: 80 + "vh",
                }], (elems_6 = toList(delay(() => map_2((report_1) => createElement("div", {
                    id: report_1.Id,
                    className: "box",
                    style: createObj(toList(delay(() => append(singleton_1(["height", 100 + "%"]), delay(() => {
                        const matchValue_2 = state.SelectedReportPage;
                        let matchResult_1, selectedReport_1;
                        if (matchValue_2 != null) {
                            if (matchValue_2.ReportId === report_1.Id) {
                                matchResult_1 = 0;
                                selectedReport_1 = matchValue_2;
                            }
                            else {
                                matchResult_1 = 1;
                            }
                        }
                        else {
                            matchResult_1 = 1;
                        }
                        switch (matchResult_1) {
                            case 0:
                                return singleton_1(["display", "block"]);
                            default:
                                return singleton_1(["display", "none"]);
                        }
                    }))))),
                }), sortBy((x_4) => x_4.Ordinal, availableReports_1, {
                    Compare: comparePrimitives,
                })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
            default: {
                const children_6 = singleton(createElement("h4", {
                    className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
                    children: "No reports available for this account",
                }));
                return createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                });
            }
        }
    }
}

