import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { BookingSearchQuery_get_Default, BookingSearchQuery as BookingSearchQuery_6, UserDataSources_$reflection, PagedDataResponse$1_$reflection, BookingSearchItem_$reflection, BookingSearchQuery_$reflection, User_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, class_type, bool_type, lambda_type, record_type, unit_type, union_type, string_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTime_formatNullable, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { createObj, defaultOf, comparePrimitives, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { map, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { iterate, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Typeahead_render, Typeahead_setInputValue, Typeahead_init } from "../Components/Typeahead.fs.js";
import { getShippers, getConsignees, getLocations } from "../Api/DataSourceApi.fs.js";
import { SearchType_Entity, Location_LocationType, Location_Entity } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { export$, search } from "../Api/BookingApi.fs.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Guid.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatBookingStatusAsClass, formatDecimalWithUnits, toDateTimeOffsetInput, formatInt } from "../Domain/Formatter.fs.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { maxValue, minValue, toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { value as value_483, toNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DateTimeOffset_fromString } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(Accounts, User, BookingSearchQuery, PreviousBookingSearchQuery, Bookings, UserDataSources, ExportBookings) {
        super();
        this.Accounts = Accounts;
        this.User = User;
        this.BookingSearchQuery = BookingSearchQuery;
        this.PreviousBookingSearchQuery = PreviousBookingSearchQuery;
        this.Bookings = Bookings;
        this.UserDataSources = UserDataSources;
        this.ExportBookings = ExportBookings;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Bookings.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["BookingSearchQuery", BookingSearchQuery_$reflection()], ["PreviousBookingSearchQuery", BookingSearchQuery_$reflection()], ["Bookings", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(BookingSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(BookingSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["UserDataSources", UserDataSources_$reflection()], ["ExportBookings", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BookingQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Bookings.Intent", [], Intent, () => [[["Item", BookingSearchQuery_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateBookingQuery", "SearchBookingsAsync", "SetSelectedOrigin", "SetSelectedDestination", "SetSelectedConsignee", "SetSelectedShipper", "ClearSearch", "ExportBookings", "SetPageNumber"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Bookings.Msg", [], Msg, () => [[["Item", lambda_type(BookingSearchQuery_$reflection(), BookingSearchQuery_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(BookingSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(BookingSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.Bookings);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.Bookings);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.Bookings)) {
        return true;
    }
    else {
        return equals_1(state.Bookings, new Deferred$1(1, []));
    }
}

function serviceLevelSearch(state) {
    const serviceLevels = sortBy((x) => x.Name, state.UserDataSources.ServiceLevels, {
        Compare: comparePrimitives,
    });
    const matchValue = state.BookingSearchQuery.ModeOfTransport;
    switch (matchValue) {
        case "Air":
            return serviceLevels.filter((x_2) => x_2.IsAir);
        case "Ground":
            return serviceLevels.filter((x_3) => x_3.IsGround);
        case "Ocean":
            return serviceLevels.filter((x_4) => x_4.IsOcean);
        default:
            return new Array(0);
    }
}

export function init(accounts, user, bookingSearchQuery, userDataSources) {
    return [new State(accounts, user, bookingSearchQuery, bookingSearchQuery, new Deferred$1(0, []), userDataSources, new Deferred$1(0, [])), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    })))];
}

export function update(msg, state) {
    let accountIds, bind$0040, bind$0040_1, bind$0040_2, x_3, bind$0040_3, x_4, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, msg.fields[1] ? state.PreviousBookingSearchQuery : state.BookingSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.UserDataSources, state.ExportBookings), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Bookings", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((accountIds = map((x_2) => x_2.Id, state.Accounts), Cmd_batch(ofArray([Typeahead_init("booking_place_of_receipt", (searchCriteria) => getLocations(new Location_Entity(1, []), new Location_LocationType(0, []), accountIds, searchCriteria), (Item_1) => (new Msg(2, [Item_1])), 500, 1), Typeahead_init("booking_destination", (searchCriteria_1) => getLocations(new Location_Entity(1, []), new Location_LocationType(1, []), accountIds, searchCriteria_1), (Item_2) => (new Msg(3, [Item_2])), 500, 1), Typeahead_init("booking_consignee_name", (searchCriteria_2) => getConsignees(new SearchType_Entity(1, []), accountIds, searchCriteria_2), (Item_3) => (new Msg(4, [Item_3])), 500, 1), Typeahead_init("booking_shipper_name", (searchCriteria_3) => getShippers(new SearchType_Entity(1, []), accountIds, searchCriteria_3), (Item_4) => (new Msg(5, [Item_4])), 500, 1)])))), new Intent(1, [])];
            }
            else if (equals_1(state.Bookings, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, new Deferred$1(1, []), state.UserDataSources, state.ExportBookings), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousBookingSearchQuery, new BookingSearchQuery_6(map((x) => x.Id, state.Accounts), bind$0040.BookingNumber, bind$0040.BookingStatus, bind$0040.PlaceOfReceiptGeoLocationId, bind$0040.DestinationGeoLocationId, bind$0040.BookingDateName, bind$0040.FromDate, bind$0040.ToDate, bind$0040.ShipperName, bind$0040.ConsigneeName, bind$0040.ModeOfTransport, bind$0040.Page, bind$0040.PageSize, bind$0040.OrderBy, bind$0040.OrderDirection))) : ((bind$0040_1 = state.BookingSearchQuery, new BookingSearchQuery_6(map((x_1) => x_1.Id, state.Accounts), bind$0040_1.BookingNumber, bind$0040_1.BookingStatus, bind$0040_1.PlaceOfReceiptGeoLocationId, bind$0040_1.DestinationGeoLocationId, bind$0040_1.BookingDateName, bind$0040_1.FromDate, bind$0040_1.ToDate, bind$0040_1.ShipperName, bind$0040_1.ConsigneeName, bind$0040_1.ModeOfTransport, 1, bind$0040_1.PageSize, bind$0040_1.OrderBy, bind$0040_1.OrderDirection))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 6: {
            iterate((value_1) => {
                Typeahead_setInputValue(value_1, "");
            }, ofArray(["booking_place_of_receipt", "booking_destination", "booking_consignee_name", "booking_shipper_name"]));
            const newState_1 = new State(state.Accounts, state.User, BookingSearchQuery_get_Default(), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.ExportBookings);
            return [newState_1, singleton((dispatch) => {
                dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.BookingSearchQuery])];
        }
        case 2:
            return [new State(state.Accounts, state.User, (bind$0040_2 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_2.AccountIds, bind$0040_2.BookingNumber, bind$0040_2.BookingStatus, (x_3 = msg.fields[0], isNullOrWhiteSpace(x_3.Id) ? defaultOf() : parse(x_3.Id)), bind$0040_2.DestinationGeoLocationId, bind$0040_2.BookingDateName, bind$0040_2.FromDate, bind$0040_2.ToDate, bind$0040_2.ShipperName, bind$0040_2.ConsigneeName, bind$0040_2.ModeOfTransport, bind$0040_2.Page, bind$0040_2.PageSize, bind$0040_2.OrderBy, bind$0040_2.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.ExportBookings), Cmd_none(), new Intent(1, [])];
        case 3:
            return [new State(state.Accounts, state.User, (bind$0040_3 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_3.AccountIds, bind$0040_3.BookingNumber, bind$0040_3.BookingStatus, bind$0040_3.PlaceOfReceiptGeoLocationId, (x_4 = msg.fields[0], isNullOrWhiteSpace(x_4.Id) ? defaultOf() : parse(x_4.Id)), bind$0040_3.BookingDateName, bind$0040_3.FromDate, bind$0040_3.ToDate, bind$0040_3.ShipperName, bind$0040_3.ConsigneeName, bind$0040_3.ModeOfTransport, bind$0040_3.Page, bind$0040_3.PageSize, bind$0040_3.OrderBy, bind$0040_3.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.ExportBookings), Cmd_none(), new Intent(1, [])];
        case 4:
            return [new State(state.Accounts, state.User, (bind$0040_4 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_4.AccountIds, bind$0040_4.BookingNumber, bind$0040_4.BookingStatus, bind$0040_4.PlaceOfReceiptGeoLocationId, bind$0040_4.DestinationGeoLocationId, bind$0040_4.BookingDateName, bind$0040_4.FromDate, bind$0040_4.ToDate, bind$0040_4.ShipperName, msg.fields[0].DisplayValue, bind$0040_4.ModeOfTransport, bind$0040_4.Page, bind$0040_4.PageSize, bind$0040_4.OrderBy, bind$0040_4.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.ExportBookings), Cmd_none(), new Intent(1, [])];
        case 5:
            return [new State(state.Accounts, state.User, (bind$0040_5 = state.BookingSearchQuery, new BookingSearchQuery_6(bind$0040_5.AccountIds, bind$0040_5.BookingNumber, bind$0040_5.BookingStatus, bind$0040_5.PlaceOfReceiptGeoLocationId, bind$0040_5.DestinationGeoLocationId, bind$0040_5.BookingDateName, bind$0040_5.FromDate, bind$0040_5.ToDate, msg.fields[0].DisplayValue, bind$0040_5.ConsigneeName, bind$0040_5.ModeOfTransport, bind$0040_5.Page, bind$0040_5.PageSize, bind$0040_5.OrderBy, bind$0040_5.OrderDirection)), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.ExportBookings), Cmd_none(), new Intent(1, [])];
        case 7:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Track And Trace", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportBookings, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.BookingSearchQuery, state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_6 = state.PreviousBookingSearchQuery, new BookingSearchQuery_6(map((x_7) => x_7.Id, state.Accounts), bind$0040_6.BookingNumber, bind$0040_6.BookingStatus, bind$0040_6.PlaceOfReceiptGeoLocationId, bind$0040_6.DestinationGeoLocationId, bind$0040_6.BookingDateName, bind$0040_6.FromDate, bind$0040_6.ToDate, bind$0040_6.ShipperName, bind$0040_6.ConsigneeName, bind$0040_6.ModeOfTransport, bind$0040_6.Page, bind$0040_6.PageSize, bind$0040_6.OrderBy, bind$0040_6.OrderDirection)), (arg_2) => (new Msg(7, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 8:
            return [new State(state.Accounts, state.User, state.BookingSearchQuery, (bind$0040_7 = state.PreviousBookingSearchQuery, new BookingSearchQuery_6(bind$0040_7.AccountIds, bind$0040_7.BookingNumber, bind$0040_7.BookingStatus, bind$0040_7.PlaceOfReceiptGeoLocationId, bind$0040_7.DestinationGeoLocationId, bind$0040_7.BookingDateName, bind$0040_7.FromDate, bind$0040_7.ToDate, bind$0040_7.ShipperName, bind$0040_7.ConsigneeName, bind$0040_7.ModeOfTransport, msg.fields[0], bind$0040_7.PageSize, bind$0040_7.OrderBy, bind$0040_7.OrderDirection)), state.Bookings, state.UserDataSources, state.ExportBookings), singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        default:
            return [new State(state.Accounts, state.User, msg.fields[0](state.BookingSearchQuery), state.PreviousBookingSearchQuery, state.Bookings, state.UserDataSources, state.ExportBookings), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_11, elems_10, elems, elems_1, matchValue, elems_5, elems_4, elems_3, elems_2, elems_9, children, elems_33, elems_32, elems_31, elems_13, elems_12, value_130, elems_15, elems_14, elems_18, elems_17, value_170, elems_16, elems_19, elems_20, elems_23, elems_22, value_214, elems_21, elems_24, value_242, elems_25, value_265, elems_26, elems_27, elems_30, elems_29, value_307, elems_28, elems_54, elems_56;
    const header = createElement("div", createObj(ofArray([["className", "box"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_10 = [createElement("div", createObj(ofArray([["className", "column"], (elems = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Bookings",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_1 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold"]),
        children: (matchValue = state.Bookings, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? ((matchValue.fields[0].fields[0].Data.length === 0) ? "0 bookings found" : (`${formatInt(~~toInt32(matchValue.fields[0].fields[0].TotalCount))} bookings found | showing page ${formatInt(matchValue.fields[0].fields[0].Page)} of ${formatInt(matchValue.fields[0].fields[0].NumberOfPages)}`)) : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow", "is-hidden"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-items-center"])], (elems_4 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold", "mr-2"]),
        children: "Sort By ",
    }), createElement("div", createObj(ofArray([["className", "select"], (elems_3 = [createElement("select", createObj(ofArray([["disabled", true], ["className", join(" ", ["is-small", "is-fullwidth"])], (elems_2 = [createElement("option", {
        value: true,
        children: "Booking Date",
    }), createElement("option", {
        value: true,
        children: "Pickup Date",
    }), createElement("option", {
        value: true,
        children: "Origin",
    }), createElement("option", {
        value: true,
        children: "Destination",
    }), createElement("option", {
        value: true,
        children: "Mode of Transport",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_9 = [(children = toList(delay(() => {
        let elems_6;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["disabled", !hasPreviousPage(state)], ["type", "button"], (elems_6 = [createElement("span", {
            className: join(" ", ["material-icons"]),
            children: "navigate_before",
        }), createElement("span", {
            className: join(" ", ["mx-1"]),
        }), createElement("span", {
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]), ["onClick", (_arg) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(8, [state.PreviousBookingSearchQuery.Page - 1]));
            }
        }]])))), delay(() => {
            let elems_7;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], (elems_7 = [createElement("span", {
                className: join(" ", ["material-icons"]),
                children: "navigate_next",
            }), createElement("span", {
                className: join(" ", ["mx-1"]),
            }), createElement("span", {
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]), ["onClick", (_arg_1) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(8, [state.PreviousBookingSearchQuery.Page + 1]));
                }
            }]])))), delay(() => {
                let elems_8;
                return state.User.CanExportBookings ? singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", equals_1(state.ExportBookings, new Deferred$1(1, [])) ? true : isExportDisabled(state)], ["onClick", (_arg_2) => {
                    dispatch(new Msg(7, [new AsyncMsg$1(0, [])]));
                }], (elems_8 = [createElement("span", {
                    className: join(" ", ["material-icons"]),
                    children: "download",
                }), createElement("span", {
                    className: join(" ", ["mx-1"]),
                }), createElement("span", {
                    children: (state.ExportBookings.tag === 1) ? "Exporting..." : "Export",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))) : empty();
            }));
        }));
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
    const searchForm = createElement("div", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (equals_1(state.Bookings, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", join(" ", ["column", "is-narrow", "full-height-container"])], (elems_33 = [createElement("form", createObj(ofArray([["className", join(" ", ["box", "is-flex", "is-flex-direction-column"])], ["style", {
        minWidth: 360 + "px",
        height: "max-content",
    }], (elems_32 = [createElement("div", createObj(ofArray([["style", {
        overflowX: "auto",
    }], (elems_31 = [createElement("div", createObj(ofArray([["className", "block"], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems_12 = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "bookingNumber",
        children: "Booking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "bookingNumber"], ["name", "bookingNumber"], ["type", "text"], ["maxLength", 100], (value_130 = state.BookingSearchQuery.BookingNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_130)) {
            e_1.value = value_130;
        }
    }]), ["placeholder", "Booking Number..."], ["onChange", (ev) => {
        const value_134 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new BookingSearchQuery_6(query.AccountIds, value_134, query.BookingStatus, query.PlaceOfReceiptGeoLocationId, query.DestinationGeoLocationId, query.BookingDateName, query.FromDate, query.ToDate, query.ShipperName, query.ConsigneeName, query.ModeOfTransport, query.Page, query.PageSize, query.OrderBy, query.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block", "is-hidden"])], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems_14 = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "bookingNumber",
        children: "Tracking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("input", {
        className: join(" ", ["input", "is-small", "is-fullwidth"]),
        id: "bookingNumber",
        name: "bookingNumber",
        type: "text",
        placeholder: "Tracking Number...",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_18 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "status",
        children: "Status",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_17 = [createElement("select", createObj(ofArray([["className", ""], ["id", "status"], ["name", "status"], (value_170 = state.BookingSearchQuery.BookingStatus, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals_1(e_2.value, value_170)) {
            e_2.value = value_170;
        }
    }]), (elems_16 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_1((status) => createElement("option", {
        value: status.StatusName,
        children: status.StatusName,
    }), state.UserDataSources.BookingStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new BookingSearchQuery_6(query_1.AccountIds, query_1.BookingNumber, element.value, query_1.PlaceOfReceiptGeoLocationId, query_1.DestinationGeoLocationId, query_1.BookingDateName, query_1.FromDate, query_1.ToDate, query_1.ShipperName, query_1.ConsigneeName, query_1.ModeOfTransport, 0, query_1.PageSize, query_1.OrderBy, query_1.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            keyPress.preventDefault();
        }
    }], (elems_19 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "origin",
        children: "Ship From",
    }), Typeahead_render("booking_place_of_receipt", "Ship From")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_1) => {
        if (keyPress_1.key === "Enter") {
            keyPress_1.preventDefault();
        }
    }], (elems_20 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "destination",
        children: "Ship To",
    }), Typeahead_render("booking_destination", "Ship To")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_23 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "shipmentDate",
        children: "Date",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_22 = [createElement("select", createObj(ofArray([["className", ""], ["id", "Date"], ["name", "Date"], (value_214 = state.BookingSearchQuery.BookingDateName, ["ref", (e_4) => {
        if (!(e_4 == null) && !equals_1(e_4.value, value_214)) {
            e_4.value = value_214;
        }
    }]), (elems_21 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Select...",
    })), delay(() => map_1((date) => createElement("option", {
        value: date.BookingDate,
        children: date.BookingDate,
    }), state.UserDataSources.BookingDates))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_2) => (isNullOrWhiteSpace(element_1.value) ? (new BookingSearchQuery_6(query_2.AccountIds, query_2.BookingNumber, query_2.BookingStatus, query_2.PlaceOfReceiptGeoLocationId, query_2.DestinationGeoLocationId, element_1.value, defaultOf(), defaultOf(), query_2.ShipperName, query_2.ConsigneeName, query_2.ModeOfTransport, query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection)) : (new BookingSearchQuery_6(query_2.AccountIds, query_2.BookingNumber, query_2.BookingStatus, query_2.PlaceOfReceiptGeoLocationId, query_2.DestinationGeoLocationId, element_1.value, query_2.FromDate, query_2.ToDate, query_2.ShipperName, query_2.ConsigneeName, query_2.ModeOfTransport, query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection)))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_24 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "fromDate"], ["name", "fromDate"], ["disabled", isNullOrWhiteSpace(state.BookingSearchQuery.BookingDateName) ? true : (state.BookingSearchQuery.BookingDateName === "Select..")], (value_242 = toDateTimeOffsetInput(state.BookingSearchQuery.FromDate), ["ref", (e_6) => {
        if (!(e_6 == null) && !equals_1(e_6.value, value_242)) {
            e_6.value = value_242;
        }
    }]), ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], ["onChange", (e_7) => {
        const element_2 = e_7.target;
        dispatch(new Msg(0, [(query_3) => (new BookingSearchQuery_6(query_3.AccountIds, query_3.BookingNumber, query_3.BookingStatus, query_3.PlaceOfReceiptGeoLocationId, query_3.DestinationGeoLocationId, query_3.BookingDateName, isNullOrWhiteSpace(element_2.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_2.value)), query_3.ToDate, query_3.ShipperName, query_3.ConsigneeName, query_3.ModeOfTransport, 0, query_3.PageSize, query_3.OrderBy, query_3.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_25 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "toDate"], ["name", "toDate"], (value_265 = toDateTimeOffsetInput(state.BookingSearchQuery.ToDate), ["ref", (e_8) => {
        if (!(e_8 == null) && !equals_1(e_8.value, value_265)) {
            e_8.value = value_265;
        }
    }]), ["disabled", isNullOrWhiteSpace(state.BookingSearchQuery.BookingDateName) ? true : (state.BookingSearchQuery.BookingDateName === "Select..")], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], ["onChange", (e_9) => {
        const element_3 = e_9.target;
        dispatch(new Msg(0, [(query_4) => (new BookingSearchQuery_6(query_4.AccountIds, query_4.BookingNumber, query_4.BookingStatus, query_4.PlaceOfReceiptGeoLocationId, query_4.DestinationGeoLocationId, query_4.BookingDateName, query_4.FromDate, isNullOrWhiteSpace(element_3.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_3.value)), query_4.ShipperName, query_4.ConsigneeName, query_4.ModeOfTransport, 0, query_4.PageSize, query_4.OrderBy, query_4.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_2) => {
        if (keyPress_2.key === "Enter") {
            keyPress_2.preventDefault();
        }
    }], (elems_26 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "shipper",
        children: "Shipper",
    }), Typeahead_render("booking_shipper_name", "Shipper")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_3) => {
        if (keyPress_3.key === "Enter") {
            keyPress_3.preventDefault();
        }
    }], (elems_27 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "consignee",
        children: "Consignee",
    }), Typeahead_render("booking_consignee_name", "Consignee")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_30 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "mot",
        children: "Mode Of Transport",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_29 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "mot"], ["name", "mot"], (value_307 = state.BookingSearchQuery.ModeOfTransport, ["ref", (e_10) => {
        if (!(e_10 == null) && !equals_1(e_10.value, value_307)) {
            e_10.value = value_307;
        }
    }]), (elems_28 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_1((mot) => createElement("option", {
        value: mot.ModeOfTransport,
        children: mot.ModeOfTransport,
    }), state.UserDataSources.ModesOfTransport))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))]), ["onChange", (e_11) => {
        const element_4 = e_11.target;
        dispatch(new Msg(0, [(query_5) => (new BookingSearchQuery_6(query_5.AccountIds, query_5.BookingNumber, query_5.BookingStatus, query_5.PlaceOfReceiptGeoLocationId, query_5.DestinationGeoLocationId, query_5.BookingDateName, query_5.FromDate, query_5.ToDate, query_5.ShipperName, query_5.ConsigneeName, element_4.value, query_5.Page, query_5.PageSize, query_5.OrderBy, query_5.OrderDirection))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])]))), createElement("div", {
        className: "is-flex-grow-1",
    }), createElement("hr", {
        className: "my-4",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.Bookings, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))))),
        type: "submit",
        children: "Search",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.Bookings, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))),
        type: "button",
        onClick: (_arg_3) => {
            dispatch(new Msg(6, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
    const searchTable = createElement("div", createObj(ofArray([["className", join(" ", ["column", "full-height-container"])], ["style", {
        overflow: "auto",
    }], (elems_54 = toList(delay(() => {
        let elems_53, elems_52, elems_51, children_2, elems_34, elems_50;
        const matchValue_8 = state.Bookings;
        let matchResult, pagedData_1;
        switch (matchValue_8.tag) {
            case 1: {
                matchResult = 0;
                break;
            }
            case 2: {
                if (matchValue_8.fields[0].tag === 0) {
                    if (compare(matchValue_8.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) {
                        matchResult = 1;
                        pagedData_1 = matchValue_8.fields[0].fields[0];
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            default:
                matchResult = 2;
        }
        switch (matchResult) {
            case 0:
                return singleton_1(render_1());
            case 1:
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_53 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], (elems_52 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_51 = [(children_2 = singleton(createElement("tr", createObj(ofArray([["className", ""], (elems_34 = [createElement("th", {
                    className: "p-2",
                    children: "Booking Number",
                }), createElement("th", {
                    className: "p-2",
                    children: "Mode of Transport",
                }), createElement("th", {
                    className: "p-2",
                    children: "Ship From",
                }), createElement("th", {
                    className: "p-2",
                    children: "Ship To",
                }), createElement("th", {
                    className: "p-2",
                    children: "Shipper",
                }), createElement("th", {
                    className: "p-2",
                    children: "Consignee",
                }), createElement("th", {
                    className: "p-2",
                    children: "Weight",
                }), createElement("th", {
                    className: "p-2",
                    children: "Volume",
                }), createElement("th", {
                    className: "p-2",
                    children: "Booking Date",
                }), createElement("th", {
                    className: "p-2",
                    children: "Pickup Date",
                }), createElement("th", {
                    className: "p-2",
                    children: "Status",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), createElement("tbody", createObj(ofArray([["className", ""], (elems_50 = toList(delay(() => map_1((booking) => {
                    let elems_49, elems_36, elems_35, elems_39, elems_38, elems_37, children_4, elems_41, elems_40, elems_43, elems_42, elems_45, elems_44, elems_47, elems_46, elems_48;
                    return createElement("tr", createObj(ofArray([["className", ""], (elems_49 = [createElement("td", createObj(ofArray([["className", "p-2"], (elems_36 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center", "is-underlined"])], (elems_35 = [createElement("a", {
                        className: join(" ", ["is-link", "is-underlined"]),
                        href: RouterModule_encodeParts(ofArray(["/booking-details", booking.Id]), 1),
                        children: booking.BookingNumber,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_39 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex"])], (elems_38 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_37 = [ModeOfTransportIcon_render(new IconType(0, []), booking.ModeOfTransportIcon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])]))), (children_4 = ofArray([createElement("a", {
                        href: RouterModule_encodeParts(ofArray(["/shipment-details", (booking.ShipmentId != null) ? value_483(booking.ShipmentId) : ""]), 1),
                        children: booking.ShipmentNumber,
                    }), createElement("div", {
                        children: [booking.ServiceType],
                    }), createElement("div", {
                        children: [booking.Service],
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_41 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_40 = [createElement("span", {
                        children: [booking.PlaceOfReceipt],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_43 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_42 = [createElement("span", {
                        children: [booking.Destination],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_45 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_44 = toList(delay(() => append(singleton_1(createElement("span", {
                        children: [booking.Shipper],
                    })), delay(() => map_1((address) => createElement("span", {
                        children: [address],
                    }), booking.ShipperAddress))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_47 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_46 = toList(delay(() => append(singleton_1(createElement("span", {
                        children: [booking.Consignee],
                    })), delay(() => map_1((address_1) => createElement("span", {
                        children: [address_1],
                    }), booking.ConsigneeAddress))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])]))), createElement("td", {
                        className: "p-2",
                        children: formatDecimalWithUnits(booking.TotalWeightImperialUnit, booking.TotalWeightImperial),
                    }), createElement("td", {
                        className: "p-2",
                        children: formatDecimalWithUnits(booking.TotalVolumeImperialUnit, booking.TotalVolumeImperial),
                    }), createElement("td", {
                        className: "p-2",
                        children: DateTime_formatNullable(booking.BookingDate),
                    }), createElement("td", {
                        className: "p-2",
                        children: DateTime_formatNullable(booking.PickupDate),
                    }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_48 = [createElement("span", {
                        className: join(" ", toList(delay(() => (!isNullOrWhiteSpace(booking.BookingStatus) ? append(singleton_1("tag"), delay(() => singleton_1(formatBookingStatusAsClass(booking.BookingStatus)))) : empty())))),
                        children: booking.BookingStatus,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])])));
                }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_51))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_52))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_53))])]))));
            default:
                return singleton_1(defaultOf_1());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_54))])])));
    const mainContent = createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray([searchForm, searchTable]),
    });
    let missingAccountContent;
    const children_6 = singleton(createElement("h4", {
        className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
        children: "Please select a client account",
    }));
    missingAccountContent = createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_56 = toList(delay(() => ((state.Accounts.length === 0) ? singleton_1(missingAccountContent) : append(singleton_1(header), delay(() => singleton_1(mainContent)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_56))])])));
}

